<template>
	<swiper 
			:allowTouchMove="false"
			:noSwiping="true"
			:autoplay="{delay: 2000, disableOnInteraction: false}"
			:loop="true"
			:speed="1"
			class="swiper-in">
			<swiper-slide>
        <div
          class="swiper-slider-img"
          style="
            background: linear-gradient(
              146deg,
              rgb(237, 218, 182) 8%,
              rgb(217, 160, 103) 88%
            );
          "
        >
          <a href="#"
            ><v-img src="@/assets/slider/Latte-1.png" alt="latte"
          /></a>
        </div>
        <div class="swiper-slider-text">
          <a href="#">Variety Pack (6)</a>
        </div>
      </swiper-slide>
			<swiper-slide>
        <div
          class="swiper-slider-img"
          style="
            background: linear-gradient(
              146deg,
              rgb(206, 185, 172) 8%,
              rgb(152, 108, 91) 88%
            );
          "
        >
          <a href="#"
            ><v-img src="@/assets/slider/Latte-2.png" alt="latte"
          /></a>
        </div>
        <div class="swiper-slider-text">
          <a href="#">Variety Pack (6)</a>
        </div>
      </swiper-slide>
			<swiper-slide>
        <div
          class="swiper-slider-img"
          style="
            background: linear-gradient(
              146deg,
              rgb(232, 202, 153) 8%,
              rgb(206, 162, 98) 88%
            );
          "
        >
          <a href="#"
            ><v-img src="@/assets/slider/Latte-3.png" alt="latte"
          /></a>
        </div>
        <div class="swiper-slider-text">
          <a href="#">Variety Pack (6)</a>
        </div>
      </swiper-slide>
			<swiper-slide>
        <div
          class="swiper-slider-img"
          style="
            background: linear-gradient(
              146deg,
              rgb(219, 143, 86) 8%,
              rgb(188, 106, 51) 88%
            );
          "
        >
          <a href="#"
            ><v-img src="@/assets/slider/Latte-4.png" alt="latte"
          /></a>
        </div>
        <div class="swiper-slider-text">
          <a href="#">Variety Pack (6)</a>
        </div>
      </swiper-slide>
			<swiper-slide>
        <div
          class="swiper-slider-img"
          style="
            background: linear-gradient(
              146deg,
              rgb(222, 198, 152) 8%,
              rgb(192, 132, 41) 88%
            );
          "
        >
          <a href="#"
            ><v-img src="@/assets/slider/Latte-5.png" alt="latte"
          /></a>
        </div>
        <div class="swiper-slider-text">
          <a href="#">Variety Pack (6)</a>
        </div>
      </swiper-slide>
			<swiper-slide>
        <div
          class="swiper-slider-img"
          style="
            background: linear-gradient(
              146deg,
              rgb(194, 196, 134) 8%,
              rgb(142, 170, 27) 88%
            );
          "
        >
          <a href="#"
            ><v-img src="@/assets/slider/Latte-6.png" alt="latte"
          /></a>
        </div>
        <div class="swiper-slider-text">
          <a href="#">Variety Pack (6)</a>
        </div>
      </swiper-slide>
			</swiper>
</template>

<script>
// import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import SwiperCore, {Autoplay,Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([Autoplay,Navigation, Pagination, Scrollbar,A11y]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
		
    return {
      onSwiper,
      onSlideChange,
      modules: [Autoplay,Navigation, Pagination, Scrollbar, A11y],
      showNavigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      },
    };
  },
};
</script>

<style scoped>

.lattes {
  position: relative;
}
.lattes .swiper-button-prev::after {
  content: none;
}
.lattes .swiper-button-next::after {
  content: none;
}
.swiper-slider-img--colored {
  background: linear-gradient(146deg, #7f7fd1 8%, #7f7fd1 88%) !important;
}
.swiper-slider-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: linear-gradient(146deg, #e0e0ff 8%, #e0e0ff 88%);
  height: 440px;
}
.swiper-slider-img a {
}
.swiper-slider-text {
  color: #373795;
  display: block;
  font-weight: Bold;
  font-size: 12px;
  letter-spacing: 1.75px;
  line-height: 1.17;
  margin-bottom: 8px;
  text-align: center;
  text-transform: uppercase;
  padding: 23px;
}
</style>