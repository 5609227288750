<template>
  <div class="blendbuy">
    <div class="blendbuy-item">
      <v-img :src="require(`@/assets/${img}`)" />
    </div>
    <div class="blendbuy-item blendbuy-item--text">
      <div class="blendbuy-title">BLENDJET 2<span>PORTABLE BLENDER </span></div>
      <div class="blendbuy__star-list">
        <div class="blendbuy-star">
          <svg width="16" height="16" viewBox="0 0 100 100" aria-hidden="true">
            <path
              stroke="yellow"
              stroke-width="7"
              fill="yellow"
              stroke-linejoin="round"
              d="M50 7l13.76 26.59 30.56 5.06-21.58 21.25 4.68 30.63-27.38-14.32
          -27.17 14.1 4.92-30.3-21.93-21.54 30.72-4.44Z"
            ></path>
          </svg>
        </div>
        <div class="blendbuy-star">
          <svg width="16" height="16" viewBox="0 0 100 100" aria-hidden="true">
            <path
              stroke="yellow"
              stroke-width="7"
              fill="yellow"
              stroke-linejoin="round"
              d="M50 7l13.76 26.59 30.56 5.06-21.58 21.25 4.68 30.63-27.38-14.32
          -27.17 14.1 4.92-30.3-21.93-21.54 30.72-4.44Z"
            ></path>
          </svg>
        </div>
        <div class="blendbuy-star">
          <svg width="16" height="16" viewBox="0 0 100 100" aria-hidden="true">
            <path
              stroke="yellow"
              stroke-width="7"
              fill="yellow"
              stroke-linejoin="round"
              d="M50 7l13.76 26.59 30.56 5.06-21.58 21.25 4.68 30.63-27.38-14.32
          -27.17 14.1 4.92-30.3-21.93-21.54 30.72-4.44Z"
            ></path>
          </svg>
        </div>
        <div class="blendbuy-star">
          <svg width="16" height="16" viewBox="0 0 100 100" aria-hidden="true">
            <path
              stroke="yellow"
              stroke-width="7"
              fill="yellow"
              stroke-linejoin="round"
              d="M50 7l13.76 26.59 30.56 5.06-21.58 21.25 4.68 30.63-27.38-14.32
          -27.17 14.1 4.92-30.3-21.93-21.54 30.72-4.44Z"
            ></path>
          </svg>
        </div>
        <div class="blendbuy-star">
          <svg width="16" height="16" viewBox="0 0 100 100" aria-hidden="true">
            <path
              stroke="yellow"
              stroke-width="7"
              fill="yellow"
              stroke-linejoin="round"
              d="M50 7l13.76 26.59 30.56 5.06-21.58 21.25 4.68 30.63-27.38-14.32
          -27.17 14.1 4.92-30.3-21.93-21.54 30.72-4.44Z"
            ></path>
          </svg>
        </div>
        <div class="blendbuy__star-text">(49,679)</div>
      </div>
      <div class="blendbuy-price">1 943,49 грн</div>
      <div class="blendbuy-color"><span>COLOR:</span> BLACK</div>
      <div class="blendbuy__cart">
        <div class="blendbuy__cart__counter">
          <button
            class="blendbuy__cart__counter-min"
            @click="decreaseCounter()"
          >
            <svg width="10px" height="10px" viewBox="0 0 10 2">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g
                  transform="translate(-1191.000000, -138.000000)"
                  fill="currentColor"
                >
                  <g transform="translate(1046.000000, 0.000000)">
                    <g transform="translate(17.000000, 54.000000)">
                      <g transform="translate(22.000000, 19.000000)">
                        <g transform="translate(96.000000, 54.000000)">
                          <rect x="10" y="11" width="10" height="2"></rect>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </button>
          <div
            type="text"
            class="blendbuy__cart__counter-input"
            id="cartCounter"
          >
            {{ counter }}
          </div>
          <button
            class="blendbuy__cart__counter-max"
            @click="increaseCounter()"
          >
            <svg width="10px" height="11px" viewBox="0 0 10 11">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g
                  transform="translate(-1253.000000, -272.000000)"
                  fill="currentColor"
                >
                  <g transform="translate(1046.000000, 0.000000)">
                    <g transform="translate(17.000000, 54.000000)">
                      <g transform="translate(0.000000, 145.000000)">
                        <g transform="translate(118.000000, 67.000000)">
                          <path
                            d="M78,6.5 L78,10.5 L82,10.5 L82,12.5 L78,12.5 L78,16.5 L76,16.5 L76,12.5 L72,12.5 L72,10.5 L76,10.5 L76,6.5 L78,6.5 Z"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </button>
        </div>
        <div class="blendbuy__cart__buy">
          <button class="blendbuy__cart__buy-btn" @click="addToCart()">
            add to cart - {{ totalPrice }} грн
          </button>
        </div>
      </div>
      <div class="blendbuy__certificates">
        <a href="#"><v-img src="@/assets/trusted-site.svg" /></a>
        <a href="#"><v-img src="@/assets/norton-site-seal.svg" /></a>
        <a href="#"><v-img src="@/assets/bbb-seal.svg" /></a>
      </div>
    </div>
  </div>
</template>

<script>
import {addToCart} from '@/requestService.js';
export default {
  data() {
    return {
      counter: 1,
      price: 1943.49,
      img: "BLENDJET-2-HERO-LANDSCAPE-CROP-BLACK.png",
			id: '2f7a4d4a-cb53-414a-a5a0-90e706b9de24',
    };
  },
  methods: {
    decreaseCounter() {
      if (this.counter > 1) {
        this.counter--;
      }
    },
    increaseCounter() {
      this.counter++;
    },
		addToCart() {
      addToCart(this.id, this.counter);
    },
  },
  computed: {
    totalPrice() {
      return (this.counter * this.price).toFixed(2);
    },
  },
};
</script>

<style scoped>
.blendbuy {
  color: #373975;
  display: flex;
}

.blendbuy-item--text {
	max-width: 550px;
	width: 100%;
	padding: 0 5px;
}
.blendbuy-title {
  font-size: 28px;
  line-height: 1.14;
  margin-bottom: 10px;
  margin-top: 45px;
  letter-spacing: 3.5px;
  text-transform: uppercase;
  text-align: center;
}
.blendbuy-title span {
  display: block;
  padding-top: 10px;
}
.blendbuy__star-list {
  display: flex;
  justify-content: center;
  margin-top: 7px;
  margin-bottom: 14px;
}
.blendbuy-price {
  font-size: 18px;
  letter-spacing: 3.5px;
  line-height: 1.07;
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
}
.blendbuy-color {
  text-align: center;
  letter-spacing: 1.75px;
  line-height: 1.33;
  font-size: 12px;
  font-weight: 700;
}
.blendbuy-color span {
  color: #999;
  padding-right: 5px;
}
.blendbuy__cart {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.blendbuy__cart__counter {
  width: 95px;
  color: #373975;
  border: 2px solid #373975;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blendbuy__cart__counter-min {
  padding: 2px 5px;
}
.blendbuy__cart__counter-input {
  background: transparent;
  border: none;
  line-height: 2rem;
  text-align: center;
  width: 2rem;
}
.blendbuy__cart__counter-max {
  padding: 2px 5px;
}
.blendbuy__cart__buy-btn {
  background-color: #373975;
  border-radius: 40px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  letter-spacing: 1.75px;
  line-height: 14px;
	min-height: 40px;
	padding: 2px 30px;
  text-transform: uppercase;
  margin-left: 10px;
	transition: all .5s;
	border: 2px solid transparent;
}
.blendbuy__cart__buy-btn:hover{
	border: 2px solid #373975;
	color: #373975;
	background: transparent;
}
.blendbuy__certificates {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px auto 20px;
  max-width: 356px;
  width: 100%;
}
@media(max-width: 1300px){
	.blendbuy{
		flex-wrap: wrap;
		justify-content: center;
	}
}
@media(max-width: 400px){
	.blendbuy-title{
		margin-top: 20px;
		font-size: 24px;
	}
	.blendbuy-title span{
		padding-top: 0;
	}
	.flendbuy__cart__buy-btn{
		padding: 0 15px;
	}
}
</style>
