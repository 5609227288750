<template>
	<div class="sale">
		<div class="sale-title">FATHER'S DAY SALE</div>
		<div class="sale__list">
			<div class="sale__list-item">
				12% OFF 1
			</div>
			<div class="sale__list-item">
				15% OFF 2
			</div>
			<div class="sale__list-item">
				20% OFF 3+
			</div>
		</div>
		<div class="sale-msg">
			<span>SAVE ON BLENDJET 2</span> | AUTOMATICALLY APPLIED AT CHECKOUT
		</div>
	</div>
</template>

<script>

</script>


<style scoped>
.sale{
	background-image: url('@/assets/DaySale.svg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	width: 100%;
}
.sale-title{
	font-size: 34px;
  letter-spacing: 4.25px;
	color: #FFF;
	padding-top: 18px;
	text-transform: uppercase;
  font-weight: 700;
	text-align: center;
}
.sale__list{
	display: flex;
	justify-content: center;
	align-items: center;
}
.sale__list-item{
	background-color: #9370d8;
  color: #ffffff;
	border-radius: 8px;
  font-size: 30px;
  letter-spacing: 5px;
  line-height: 45px;
  margin: 8px 15px 0;
  padding: 0 18px;
}
.sale-msg{
	font-size: 18px;
  line-height: 26px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	color: #a9a9a9;
	text-align: center;
	margin-top: 10px;
	padding-bottom: 18px;
}
.sale-msg span{
	color: #6495ed;
}
@media(max-width: 800px){
	.sale__list-item{
		font-size: 24px;
		margin: 8px 8px 0;
	}
}
@media(max-width: 678px){
	.sale__list-item{
		padding: 0 8px;
		font-size: 20px;
		line-height: 30px;
	}
	.sale-msg{
		font-size: 16px;
	}
}
@media(max-width: 600px){
	.sale-msg{
		font-size: 14px;
		line-height: 20px;
	}
}
@media(max-width: 550px){
	.sale__list{
		flex-wrap: wrap;
	}
}
@media(max-width: 400px){
	.sale-title{
		padding-left: 5px;
		padding-right: 5px;
		font-size: 26px;

	}
}
@media(max-width: 350px){
	.sale-msg{
		padding-left: 5px;
		padding-right: 5px;
		font-size: 12px;
		line-height: 18px;
	}
}
</style>