<template>
  <div class="review">
    <div class="review-title">
      WHAT OUR CUSTOMERS THINK OF OUR PORTABLE BLENDER
    </div>
    <swiper
      loop
      :navigation="showNavigation"
      class="review-swiper"
      :autoplay="{
        delay: 3000,
      }"
    >
      <swiper-slide v-for="slide in slides" :key="slide.id">
        <div class="list">
          <div class="review__text">
            {{ slide.text }}
          </div>
          <div class="review__author">{{ slide.author }}</div>
        </div>
      </swiper-slide>
      <div class="swiper-button-prev swiper-button-prev--review">
        <svg width="29px" height="29px" viewBox="0 0 29 29">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-217.000000, -414.000000)">
              <g
                transform="translate(231.500000, 428.500000) scale(-1, 1) translate(-231.500000, -428.500000) translate(218.000000, 415.000000)"
              >
                <circle
                  stroke="#373795"
                  stroke-width="1.5"
                  fill="#FFFFFF"
                  cx="13.5"
                  cy="13.5"
                  r="13.5"
                ></circle>
                <g
                  transform="translate(14.500000, 13.000000) scale(-1, 1) translate(-14.500000, -13.000000) translate(10.000000, 6.000000)"
                  fill="#373795"
                >
                  <polygon
                    transform="translate(4.500015, 4.499985) rotate(-45.000000) translate(-4.500015, -4.499985)"
                    points="3.72753246e-05 3.59998456 8.9999936 3.59998456 8.9999936 5.39998456 3.72753246e-05 5.39998456"
                  ></polygon>
                  <polygon
                    transform="translate(4.500015, 9.578392) scale(1, -1) rotate(-45.000000) translate(-4.500015, -9.578392)"
                    points="3.72753236e-05 8.67839206 8.9999936 8.67839206 8.9999936 10.4783921 3.72753236e-05 10.4783921"
                  ></polygon>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class="swiper-button-next swiper-button-next--review">
        <svg width="29px" height="29px" viewBox="0 0 29 29">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-1194.000000, -414.000000)">
              <g transform="translate(1195.000000, 415.000000)">
                <circle
                  stroke="#373795"
                  stroke-width="1.5"
                  fill="#FFFFFF"
                  cx="13.5"
                  cy="13.5"
                  r="13.5"
                ></circle>
                <g
                  transform="translate(14.500000, 13.000000) scale(-1, 1) translate(-14.500000, -13.000000) translate(10.000000, 6.000000)"
                  fill="#373795"
                >
                  <polygon
                    transform="translate(4.500015, 4.499985) rotate(-45.000000) translate(-4.500015, -4.499985)"
                    points="3.72753223e-05 3.59998456 8.9999936 3.59998456 8.9999936 5.39998456 3.72753223e-05 5.39998456"
                  ></polygon>
                  <polygon
                    transform="translate(4.500015, 9.578392) scale(1, -1) rotate(-45.000000) translate(-4.500015, -9.578392)"
                    points="3.72753221e-05 8.67839206 8.9999936 8.67839206 8.9999936 10.4783921 3.72753221e-05 10.4783921"
                  ></polygon>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </swiper>
  </div>
</template>

<script scoped>
import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from "swiper";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
export default {
  data() {
    return {
      slides: [
        {
          id: 1,
          text: '"As a proud owner of the original BlendJet, I couldn’t wait for the release of the BlendJet 2. More power, longer battery life, and better blends. Every home should have at least one BlendJet2!"',
          author: "Kyle Zukowski",
        },
        {
          id: 2,
          text: '"I loved the original, but this new, improved BlendJet 2 is AMAZING! So powerful and blends so smoothly for my on-the-go lifestyle!"',
          author: "Astrid Swan",
        },
        { id: 3, text: '"BEST PORTABLE BLENDER BAR NONE!"', author: "T3.com" },
        {
          id: 4,
          text: '"Loving my new BlendJet 2! It’s super convenient, quiet, and easy to clean. I have a powerful full size blender and this little blender is just as strong with about a quarter of the noise."',
          author: "Jenn C.S. Rubin",
        },
        {
          id: 5,
          text: '"Very impressed! I use my BlendJet for my morning smoothies and it is fantastic. It performs better than my plug-in blender. Light and portable, very powerful, and the battery lasts a long time. 5 stars!"',
          author: "Chris E.",
        },
      ],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Autoplay, Navigation, Pagination, Scrollbar, A11y],
      showNavigation: {
        prevEl: ".swiper-button-prev--review",
        nextEl: ".swiper-button-next--review",
      },
    };
  },
};
</script>

<style scoped>
.swiper-button-next--review::after,
.swiper-button-prev--review::after {
  content: none;
}

.review-title {
  color: #373975;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1.75px;
  line-height: 1.17;
  margin-top: 60px;
	margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
}
.list {
  color: #373975;
	padding: 0 5px;
}
.review__text {
  font-style: italic;
  text-align: center;
  font-size: 29px;
  line-height: 30px;
  letter-spacing: 1px;
  margin: 0 auto;
  max-width: 700px;
  width: 100%;
}
.review__author {
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1.75px;
  line-height: 1.17;
  margin-top: 50px;
  text-transform: uppercase;
  text-align: center;
	margin-bottom: 30px;
}
@media(max-width: 768px){
	.swiper-button-prev--review,
	.swiper-button-next--review{
		display: none;
	}
}
@media(max-width: 500px){
	.review__text{
		font-size: 24px;
		line-height: 30px;
	}
	.review-title{
		letter-spacing: 0.75px;
		margin-top: 30px;
		margin-bottom: 15px;
	}
	.review__author {
		margin-top: 30px;
	}
}
@media(max-width: 400px){
	.review__text{
		font-size: 20px;
		line-height: 24px;
	}
}
</style>
