<template>
  <div class="everywhere">
    <div class="everywhere-title">BLEND EVERYWHERE</div>
    <div class="everywhere-desc">
      Imagine the freedom of being able to go anywhere and blend your favorite
      smoothies, shakes, margaritas, frappés, or baby food without the
      limitations of a regular blender.
    </div>
  </div>
</template>


<style scoped>
.everywhere {
	background: linear-gradient(to bottom right,#373795,#1e90bb);
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 205px;
	padding-bottom: 20px;
  padding-top: 20px;
	text-align: center;
	flex-direction: column;
}
.everywhere-title {
	font-size: 24px;
  letter-spacing: 4px;
  line-height: 1.17;
  margin-bottom: 15px;
  text-transform: uppercase;
	font-weight: 400;
}
.everywhere-desc {
	max-width: 700px;
  padding: 15px;
	font-weight: 400;
	line-height: 1.5;
}
@media(max-width: 500px){
	.everywhere-title{
		margin-bottom: 0;
	}
	.everywhere-desc{
		padding: 5px;
	}
}
@media(max-width: 400px){
	.everywhere{
		min-height: auto;
		padding-top: 12px;
		padding-bottom: 12px;
	}
	.everywhere-title{
		font-size: 20px;
	}
	.everywhere-desc{
		font-size: 14px;
		line-height: 22px;
		text-align: justify;
	}
}
</style>