<template>
  <div class="lattes">
    <swiper :slides-per-view="4" :navigation="showNavigation" :breakpoints="swiperBreakpoints">
      <swiper-slide>
        <LattesSliderComp />
      </swiper-slide>
      <swiper-slide>
        <div
          class="swiper-slider-img"
          style="
            background: linear-gradient(
              146deg,
              rgb(237, 218, 182) 8%,
              rgb(217, 160, 103) 88%
            );
          "
        >
          <a href="#"
            ><v-img src="@/assets/slider/Latte-1.png" alt="latte"
          /></a>
        </div>
        <div class="swiper-slider-text">
          <a href="#">caramel</a>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div
          class="swiper-slider-img"
          style="
            background: linear-gradient(
              146deg,
              rgb(206, 185, 172) 8%,
              rgb(152, 108, 91) 88%
            );
          "
        >
          <a href="#"
            ><v-img src="@/assets/slider/Latte-2.png" alt="latte"
          /></a>
        </div>
        <div class="swiper-slider-text">
          <a href="#">mocha</a>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div
          class="swiper-slider-img"
          style="
            background: linear-gradient(
              146deg,
              rgb(232, 202, 153) 8%,
              rgb(206, 162, 98) 88%
            );
          "
        >
          <a href="#"
            ><v-img src="@/assets/slider/Latte-3.png" alt="latte"
          /></a>
        </div>
        <div class="swiper-slider-text">
          <a href="#">vanilla</a>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div
          class="swiper-slider-img"
          style="
            background: linear-gradient(
              146deg,
              rgb(219, 143, 86) 8%,
              rgb(188, 106, 51) 88%
            );
          "
        >
          <a href="#"
            ><v-img src="@/assets/slider/Latte-4.png" alt="latte"
          /></a>
        </div>
        <div class="swiper-slider-text">
          <a href="#">chai</a>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div
          class="swiper-slider-img"
          style="
            background: linear-gradient(
              146deg,
              rgb(222, 198, 152) 8%,
              rgb(192, 132, 41) 88%
            );
          "
        >
          <a href="#"
            ><v-img src="@/assets/slider/Latte-5.png" alt="latte"
          /></a>
        </div>
        <div class="swiper-slider-text">
          <a href="#">cinnamon dolce</a>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div
          class="swiper-slider-img"
          style="
            background: linear-gradient(
              146deg,
              rgb(194, 196, 134) 8%,
              rgb(142, 170, 27) 88%
            );
          "
        >
          <a href="#"
            ><v-img src="@/assets/slider/Latte-6.png" alt="latte"
          /></a>
        </div>
        <div class="swiper-slider-text">
          <a href="#">matcha green tea</a>
        </div>
      </swiper-slide>
    </swiper>
    <div class="swiper-button-prev">
      <svg width="29px" height="29px" viewBox="0 0 29 29">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-217.000000, -414.000000)">
            <g
              transform="translate(231.500000, 428.500000) scale(-1, 1) translate(-231.500000, -428.500000) translate(218.000000, 415.000000)"
            >
              <circle
                stroke="#373795"
                stroke-width="1.5"
                fill="#FFFFFF"
                cx="13.5"
                cy="13.5"
                r="13.5"
              ></circle>
              <g
                transform="translate(14.500000, 13.000000) scale(-1, 1) translate(-14.500000, -13.000000) translate(10.000000, 6.000000)"
                fill="#373795"
              >
                <polygon
                  transform="translate(4.500015, 4.499985) rotate(-45.000000) translate(-4.500015, -4.499985)"
                  points="3.72753246e-05 3.59998456 8.9999936 3.59998456 8.9999936 5.39998456 3.72753246e-05 5.39998456"
                ></polygon>
                <polygon
                  transform="translate(4.500015, 9.578392) scale(1, -1) rotate(-45.000000) translate(-4.500015, -9.578392)"
                  points="3.72753236e-05 8.67839206 8.9999936 8.67839206 8.9999936 10.4783921 3.72753236e-05 10.4783921"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <div class="swiper-button-next">
      <svg width="29px" height="29px" viewBox="0 0 29 29">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-1194.000000, -414.000000)">
            <g transform="translate(1195.000000, 415.000000)">
              <circle
                stroke="#373795"
                stroke-width="1.5"
                fill="#FFFFFF"
                cx="13.5"
                cy="13.5"
                r="13.5"
              ></circle>
              <g
                transform="translate(14.500000, 13.000000) scale(-1, 1) translate(-14.500000, -13.000000) translate(10.000000, 6.000000)"
                fill="#373795"
              >
                <polygon
                  transform="translate(4.500015, 4.499985) rotate(-45.000000) translate(-4.500015, -4.499985)"
                  points="3.72753223e-05 3.59998456 8.9999936 3.59998456 8.9999936 5.39998456 3.72753223e-05 5.39998456"
                ></polygon>
                <polygon
                  transform="translate(4.500015, 9.578392) scale(1, -1) rotate(-45.000000) translate(-4.500015, -9.578392)"
                  points="3.72753221e-05 8.67839206 8.9999936 8.67839206 8.9999936 10.4783921 3.72753221e-05 10.4783921"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>


<script>
import LattesSliderComp from "@/components/Sliders/LattesSliderComp.vue";
// import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar, A11y]);

export default {
  components: {
    Swiper,
    SwiperSlide,
    LattesSliderComp,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };

    return {
      onSwiper,
      onSlideChange,
      modules: [Autoplay, Navigation, Pagination, Scrollbar, A11y],
      showNavigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      },
			swiperBreakpoints: {
				1300: {
					slidesPerView: 4,
				},
				1000: {
					slidesPerView:3,
				},
				700: {
					slidesPerView:2,
				},
				300: {
					slidesPerView:1,
				}
			}
    };
  },
};
</script>


<style scoped>
.lattes {
  position: relative;
}
.lattes .swiper-button-prev::after {
  content: none;
}
.lattes .swiper-button-next::after {
  content: none;
}
.swiper-slider-img--colored {
  background: linear-gradient(146deg, #7f7fd1 8%, #7f7fd1 88%) !important;
}
.swiper-slider-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: linear-gradient(146deg, #e0e0ff 8%, #e0e0ff 88%);
  height: 440px;
}

.swiper-slider-text {
  color: #373795;
  display: block;
  font-weight: Bold;
  font-size: 12px;
  letter-spacing: 1.75px;
  line-height: 1.17;
  margin-bottom: 8px;
  text-align: center;
  text-transform: uppercase;
  padding: 23px;
}
</style>