<template>
  <div class="home">
    <div class="home__text">
      The Original
      <span>Portable Blender</span>
      <div class="home-btn">
        <a href="#/products/blendjet-2" class="default-btn">GET YOURS NOW</a>
      </div>
    </div>
    <div class="home__video">
      <video autoplay="" loop="" muted="" webkit-playsinline="" playsinline="">
        <source :src="videoUrl" type="video/mp4" />
      </video>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoUrl: require("@/assets/HomePage_video.mp4"),
    };
  },
};
</script>

<style scoped>
.home {
  display: flex;
  align-items: center;
  flex-direction: column;
	position: relative;
}
.home__text {
  font-size: 54px;
  letter-spacing: 10px;
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 10px #7a7a7a;
  font-weight: 400;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 2;
}
.home__text span{
	display: block;
}
.home-btn {
  display: flex;
  justify-content: center;
	margin-top: 20px;
}
video {
  aspect-ratio: 480/187;
  display: block;
  object-fit: cover;
  object-position: center;
  width: 100%;
}
@media(max-width: 678px){
	.home__text{
		font-size: 32px;
		letter-spacing: normal;
	}
}
@media(max-width: 500px){
	.default-btn{
		padding: 10px 40px;
	}
	.home-btn{
		padding-bottom: 5px;
	}
	.home__text{
		padding-top: 5px;
	}
	.home__video video{
		height: 200px;
	}
}

</style>