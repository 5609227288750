import {createRouter, createWebHashHistory} from 'vue-router';
import UserGuide from '@/components/Pages/UserGuide/UserGuide.vue';
import HomePage from '@/components/Pages/HomePage/HomePage.vue';
import BlendJet from '@/components/Pages/Blendjet/BlendJet.vue';

export default createRouter({
	history: createWebHashHistory(),
	routes: [
		{path: '/Home', component: HomePage, alias: '/'},
		{path: '/user-guide/blendjet-2', component: UserGuide},
		{path: '/products/blendjet-2', component: BlendJet},
	]
})