<template>
	<div class="slider">
		<div class="slider-track">
			<div class="slide">
				Free shipping
			</div>
			<div class="slide">
				Free shipping
			</div>
			<div class="slide">
				Free shipping
			</div>
			<div class="slide">
				Free shipping
			</div>
			<div class="slide">
				Free shipping
			</div>
			<div class="slide">
				Free shipping
			</div>
			<div class="slide">
				Free shipping
			</div>
			<div class="slide">
				Free shipping
			</div>
			<div class="slide">
				Free shipping
			</div>
			<div class="slide">
				Free shipping
			</div>
			<div class="slide">
				Free shipping
			</div>
			<div class="slide">
				Free shipping
			</div>
			<div class="slide">
				Free shipping
			</div>
			<div class="slide">
				Free shipping
			</div>
		</div>
	</div>
</template>

<script>

</script>
<style scoped>
.slider{
	margin: auto;
	overflow: hidden;
	position: relative;
	width: auto;
	background: linear-gradient(270deg,#373795 0,#1e90bb);
}
.slider-track{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80px;
}
.slide{
	font-size: 18px;
	letter-spacing: 2.5px;
	line-height: 1.33;
	text-transform: uppercase;
	color: #FFF;
	width: 250px;
	font-style: italic;
}
.slider .slider-track{
	animation: scroll 40s linear infinite;
	display: flex;
	width: calc(250px * 14);
}
@keyframes scroll{
	0%{
	transform: translateX(0);
	}
	100%{
		transform: translateX(calc(-250px * 7));
	}
}
@media(max-width: 700px){
	.slider-track{
		height: 60px;
	}
	.slide{
		font-size: 16px;
	}
}
@media(max-width: 400px){
	.slider-track{
		height: 40px;
	}
	.slider{
		font-size: 13px;
	}
}
</style>