<template>
  <div class="slider">
    <div class="slider-title">AS SEEN ON:</div>
    <div class="slider-track">
      <span class="slide"
        ><v-img
          src="@/assets/slider/company-1.png"
          alt="Yahoo News Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-2.png"
          alt="Fox News Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-3.png"
          alt="CNN Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-4.png"
          alt="MSN Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-5.png"
          alt="NBC News Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-6.png"
          alt="People Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-7.png"
          alt="USA Today Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-8.png"
          alt="Jimmy Kimmel Live Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-9.png"
          alt="CBS News Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-10.png"
          alt="The Kelly Clarkson Show Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-11.png"
          alt="Food Network Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-12.png"
          alt="Bravo Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-13.png"
          alt="The Today Show Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-14.png"
          alt="Shape Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-15.png"
          alt="HGTV Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-16.png"
          alt="Health Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-17.png"
          alt="Forbes Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-18.png"
          alt="Good Morning America Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-19.png"
          alt="CNET Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-20.png"
          alt="Vogue Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-21.png"
          alt="Essence Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-22.png"
          alt="Allure Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-23.png"
          alt="New York Magazine Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-24.png"
          alt="Refinery29 Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-25.png"
          alt="Wired Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-26.png"
          alt="Parents Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-27.png"
          alt="Good Housekeeping Logo"/></span
      ><span class="slide"
        ><v-img
          src="@/assets/slider/company-28.png"
          alt="Martha Stewart Living Logo"/></span
      >
    </div>
  </div>
</template>

<style scoped>
.slider-title {
  color: #7f7fd1;
  font-size: 12px;
  letter-spacing: 1.75px;
  line-height: 1.17;
  margin-bottom: 16px;
  padding-top: 25px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
	font-style: normal;
  font-weight: 700;
}
.slider {
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;
  background-color: #373975;
}
.slider-track {
  height: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slider .slider-track{
	animation: scroll 30s linear infinite;
	display: flex;
	align-items: center;
	width: calc(250px * 14);
}
.slide{
margin: 0 30px;
}
.slide img{
	max-width: 192px;
	height: auto;
}
@keyframes scroll{
	0%{
	transform: translateX(0);
	}
	100%{
		transform: translateX(calc(-250px * 7));
	}
}
@media(max-width: 678px){
	.slider-track{
		height: 80px;
		padding-bottom: 10px;
	}
	.slider-title{
		padding-top: 15px;
	}
}

</style>