<template>
<UserGuideDesc />
<FirstUse />
<AnatomyGuide />
</template>

<script>
import UserGuideDesc from '@/components/Pages/UserGuide/UserGuideDesc.vue';
import FirstUse from '@/components/Pages/UserGuide/FirstUse.vue';
import AnatomyGuide from '@/components/Pages/UserGuide/AnatomyGuide.vue';
export default{
name: "UserGuide",
components:{
	UserGuideDesc,
	FirstUse,
	AnatomyGuide
}
}
</script>

<style scoped>

</style>