<template>
	<HomeBanner />
	<DaySale />
	<CompaniesSlider />
	<IntroBlengjed />
	<FreeShipping />
	<JetpackSlider />
	<AccesoriesSlider />
	<FollowUs />
	<ReviewSlider />
</template>

<script>
import HomeBanner from '@/components/Pages/HomePage/HomeBanner.vue';
import DaySale from '@/components/Fitches/DaySale.vue';
import CompaniesSlider from '@/components/Sliders/CompaniesSlider.vue';
import IntroBlengjed from '@/components/Fitches/IntroBlengjed.vue';
import FreeShipping from '@/components/Sliders/FreeShipping.vue';
import ReviewSlider from '@/components/Sliders/ReviewSlider.vue';
import FollowUs from '@/components/Fitches/FollowUs.vue';
import AccesoriesSlider from '@/components/Sliders/AccesoriesSlider.vue';
import JetpackSlider from '@/components/Sliders/JetpackSlider.vue';
export default{
	name: 'HomePage',
	components:{
		HomeBanner,
		DaySale,
		CompaniesSlider,
		IntroBlengjed,
		ReviewSlider,
		FreeShipping,
		FollowUs,
		AccesoriesSlider,
		JetpackSlider
	}
}
</script>