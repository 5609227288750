import axios from 'axios';

const var_host = 'https://api.dev.mvsb.top/api/v1/';
const uuid_form = 'f058a517-685b-4e9d-bab3-667dda32fee0';
const uuid_cart = "488033ab-2c30-4bae-a21c-e09ca6be6769";
let phone = () =>{
	return '0' + Math.floor(Math.random() * 1e10).toString().padStart(10, '0');
};
export async function initCart(){
  const formData = new FormData();
  formData.append('uuid_form', uuid_form);
  try {
    const response = await axios.post(`${var_host}crm/boards/forms/cart/init`, formData);
    console.log("Response:", response.data);
  } catch (error) {
    console.error("Error: ", error);
  }
}
export async function addToCart(id,amount){
	const formData = new FormData();
  formData.append('uuid_cart', uuid_cart);
	formData.append('uuid_form_item', id);
	formData.append('amount', amount);
	try {
    const response = await axios.post(`${var_host}crm/boards/forms/cart/items/add`, formData);
    console.log("Response(add to cart):", response.data);
		console.log(id);
		console.log(amount);
  } catch (error) {
    console.error("Error: ", error);
  }
}
export async function submitCart(){
	const formData = new FormData();
  formData.append('uuid_cart', uuid_cart);
	formData.append("phone", phone());
	try {
    const response = await axios.post(`${var_host}crm/boards/forms/cart/submit/${uuid_cart}`, formData);
    console.log("Response(submit):", response.data);
  } catch (error) {
    console.error("Error: ", error);
  }
}