<template>
  <div class="accesories">
    <div class="accesories-title">ACCESSORIES</div>
    <div class="accesories-slider">
      <swiper :slides-per-view="3" loop :breakpoints="swiperBreakpoints" :autoplay="{delay: 5000, disableOnInteraction: false}">
        <swiper-slide>
          <div class="swiper-slider-img">
            <a href="#"
              ><v-img src="@/assets/slider/accesories-1.png" alt=""
            /></a>
          </div>
          <div class="swiper-slider-text">
            <a href="#">Orbiter Drinking Lid</a>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-slider-img--colored swiper-slider-img">
            <a href="#"
              ><v-img src="@/assets/slider/accesories-2.png" alt=""
            /></a>
          </div>
          <div class="swiper-slider-text">
            <a href="#">Jetsetter Insulated Sleeve</a>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-slider-img">
            <a href="#"
              ><v-img src="@/assets/slider/accesories-3.png" alt=""
            /></a>
          </div>
          <div class="swiper-slider-text">
            <a href="#">XL Jar (32 oz)</a>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-slider-img--colored swiper-slider-img">
            <a href="#"
              ><v-img src="@/assets/slider/accesories-4.png" alt=""
            /></a>
          </div>
          <div class="swiper-slider-text">
            <a href="#">Large Jar (20 oz)</a>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-slider-img">
            <a href="#"
              ><v-img src="@/assets/slider/accesories-5.png" alt=""
            /></a>
          </div>
          <div class="swiper-slider-text">
            <a href="#">Next-Gen Blending - Recipe Book</a>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-slider-img--colored swiper-slider-img">
            <a href="#"
              ><v-img src="@/assets/slider/accesories-6.png" alt=""
            /></a>
          </div>
          <div class="swiper-slider-text">
            <a href="#">Next-Gen Blending - Recipe Book Vol. 2</a>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
// import {Autoplay, Navigation, Pagination, Scrollbar, A11y} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Autoplay, Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar, A11y]);

export default {
  data() {
    return {};
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Autoplay, Navigation, Pagination, Scrollbar, A11y],
      showNavigation: false,
      swiperBreakpoints: {
        1300: {
          slidesPerView: 3,
					autoplay: {
            delay: 5000,
            disableOnInteraction: true,
          },
        },
        700: {
          slidesPerView: 2,
					autoplay: {
            delay: 5000,
            disableOnInteraction: true,
          },
        },
        300: {
          slidesPerView: 1,
          autoplay: {
            delay: 3000,
            disableOnInteraction: true,
          },
        },
      },
    };
  },
};

</script>
<style scoped>
.accesories {
  margin-top: 40px;
}
.accesories-title {
  color: #373975;
  font-weight: Bold;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 3.5px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 42px;
}

.swiper-slider-img--colored {
  background: linear-gradient(146deg, #7f7fd1 8%, #7f7fd1 88%) !important;
}
.swiper-slider-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: linear-gradient(146deg, #e0e0ff 8%, #e0e0ff 88%);
  height: 440px;
}

.swiper-slider-text {
  color: #373795;
  display: block;
  font-weight: Bold;
  font-size: 12px;
  letter-spacing: 1.75px;
  line-height: 1.17;
  margin-bottom: 8px;
  text-align: center;
  text-transform: uppercase;
  padding: 23px;
}
</style>
