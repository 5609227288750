<template>
	<DaySale />
	<BlendjedBuy />
	<EveryWhere />
	<BlendjetSlider />
	<FeaturedBlend />
</template>

<script>
import BlendjedBuy from '@/components/Pages/Blendjet/BlendjetBuy.vue';
import EveryWhere from '@/components/Pages/Blendjet/EveryWhere.vue';
import BlendjetSlider from '@/components/Sliders/BlendjedSlider.vue';
import FeaturedBlend from '@/components/Pages/Blendjet/FeaturedBlend.vue';
import DaySale from '@/components/Fitches/DaySale.vue';
export default{
	name: 'BlendJet',
	components:{
		EveryWhere,
		BlendjetSlider,
		FeaturedBlend,
		BlendjedBuy,
		DaySale
	}
}
</script>