<template>
	<div class="wrapper">
		<div class="text">
			<h1 class="text-title">BlendJet 2 User Guide</h1>
			<div class="text-desc">Congrats! You’re about to embark on an amazing journey with your BlendJet 2. Here you’ll find everything you need to know to get the most out of your blender.</div>
		</div>
		<div class="img">
			<v-img src="@/assets/220415_BJ2_welcome_card_img-HIRES-TRIO_2__1_.png" alt="" />
		</div>
	</div>
</template>


<style scoped>
.wrapper{
	display: flex;
	justify-content: space-around;
	align-items: center;
	background: linear-gradient(90deg,#373795 0,#1e90bb);
	padding: 5px;
}
.text{
	max-width: 350px;
}
.text-title{
	color: #fff;
  font-weight: Medium;
  font-size: 36px;
  letter-spacing: 6px;
  line-height: 1.17;
  text-align: center;
  text-transform: uppercase;
}
.text-desc{
	font-weight: Regular;
  font-size: 14px;
  letter-spacing: .5px;
  line-height: 1.29;
  text-align: center;
}
@media(max-width: 768px){
	.wrapper{
		flex-wrap: wrap;
	}
	.text-title{
		margin-bottom: 10px;
	}
}
@media(max-width: 500px){
	.wrapper{
		padding: 20px 5px;
	}
}
</style>