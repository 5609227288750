<template>
	<div class="wrapper">
		<div class="icon"><svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink"><title>i</title><g id="PDP" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="D-BlendJet-PDP-BlendJet" transform="translate(-1319.000000, -483.000000)"><g id="BUY" transform="translate(0.000000, 77.000000)"><g id="Side" transform="translate(935.000000, 32.000000)"><g id="Group-5" transform="translate(70.000000, 65.000000)"><g id="After-Ppay" transform="translate(2.000000, 310.000000)"><g id="Group-12" transform="translate(26.000000, 0.000000)"><g id="i" transform="translate(287.000000, 0.000000)"><circle id="Oval-Copy-7" stroke="#373795" cx="8.5" cy="8.5" r="8.5"></circle><text font-family="Helvetica" font-size="12" font-weight="normal" line-spacing="18" letter-spacing="0.428571429" fill="#373795"><tspan x="7.5" y="13">i</tspan></text></g></g></g></g></g></g></g></g></svg></div>
		<div class="text">
			<a href="#">Before first use, be sure to review important safety information.</a>
		</div>
	</div>
	<div class="wrapper" style="border-color: #cccccc">
		<div class="text">
			<a href="#" style="color: #3273dc;">Click here for the BlendJet One Guide.</a>
		</div>
	</div>
</template>


<style scoped>
.wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid red;
  border-radius: 12px;
	padding: 12px 0;
	max-width: 95%;
	margin: 39px auto 35px auto;
}
.icon{
	margin-right: 16px;
}
.text a{
	color: #373975;
  font-weight: Regular;
  font-size: 14px;
  letter-spacing: .5px;
  line-height: 1.29;
}
@media(max-width: 520px){
	.wrapper{
		padding: 4px 2px;
		margin: 15px auto;
	}
	.text a{
		font-size: 12px;
	}
	.icon{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 5px;
		margin-left: 5px;
	}
}
</style>