<template>
	<swiper loop :navigation="showNavigation">
		<swiper-slide v-for="slide in slides" :key="slide.id">
			<v-img :src="require(`@/assets/slider/${slide.img}`)" />
		</swiper-slide>
		<div class="swiper-button-prev">
      <svg width="29px" height="29px" viewBox="0 0 29 29">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-217.000000, -414.000000)">
            <g
              transform="translate(231.500000, 428.500000) scale(-1, 1) translate(-231.500000, -428.500000) translate(218.000000, 415.000000)"
            >
              <circle
                stroke="#373795"
                stroke-width="1.5"
                fill="#FFFFFF"
                cx="13.5"
                cy="13.5"
                r="13.5"
              ></circle>
              <g
                transform="translate(14.500000, 13.000000) scale(-1, 1) translate(-14.500000, -13.000000) translate(10.000000, 6.000000)"
                fill="#373795"
              >
                <polygon
                  transform="translate(4.500015, 4.499985) rotate(-45.000000) translate(-4.500015, -4.499985)"
                  points="3.72753246e-05 3.59998456 8.9999936 3.59998456 8.9999936 5.39998456 3.72753246e-05 5.39998456"
                ></polygon>
                <polygon
                  transform="translate(4.500015, 9.578392) scale(1, -1) rotate(-45.000000) translate(-4.500015, -9.578392)"
                  points="3.72753236e-05 8.67839206 8.9999936 8.67839206 8.9999936 10.4783921 3.72753236e-05 10.4783921"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <div class="swiper-button-next">
      <svg width="29px" height="29px" viewBox="0 0 29 29">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-1194.000000, -414.000000)">
            <g transform="translate(1195.000000, 415.000000)">
              <circle
                stroke="#373795"
                stroke-width="1.5"
                fill="#FFFFFF"
                cx="13.5"
                cy="13.5"
                r="13.5"
              ></circle>
              <g
                transform="translate(14.500000, 13.000000) scale(-1, 1) translate(-14.500000, -13.000000) translate(10.000000, 6.000000)"
                fill="#373795"
              >
                <polygon
                  transform="translate(4.500015, 4.499985) rotate(-45.000000) translate(-4.500015, -4.499985)"
                  points="3.72753223e-05 3.59998456 8.9999936 3.59998456 8.9999936 5.39998456 3.72753223e-05 5.39998456"
                ></polygon>
                <polygon
                  transform="translate(4.500015, 9.578392) scale(1, -1) rotate(-45.000000) translate(-4.500015, -9.578392)"
                  points="3.72753221e-05 8.67839206 8.9999936 8.67839206 8.9999936 10.4783921 3.72753221e-05 10.4783921"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
	</swiper>
</template>

<script>
	import { Swiper, SwiperSlide } from "swiper/vue";
	import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar, A11y]);
export default{
	data(){
		return{
			slides:[
				{id: 1,	img: 'blendjet-1.jpg'},
				{id: 2,	img: 'blendjet-2.jpg'},
				{id: 3,	img: 'blendjet-3.jpg'},
				{id: 4,	img: 'blendjet-4.jpg'}
			]
		}
	},
	components: {
    Swiper,
    SwiperSlide
  },
	setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
			showNavigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      },
    };
  },
}
</script>
<style scoped>
.swiper-button-prev::after,.swiper-button-next::after{
	content: none;
}
</style>