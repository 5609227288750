<template>
	<div class="intro">
		<div class="intro-title">INTRODUCING BLENDJET 2</div>
		<div class="intro-subtitle">NEXT-GEN PORTABLE BLENDER</div>
		<div class="intro-desc">
			<div>The BlendJet 2 portable blender delivers game-changing technology that lets you make fresh smoothies, shakes, or mixed drinks wherever you go.</div>
		</div>
			<div class="intro__list">
			<div class="intro__list-item">
				<v-img class="intro__list-item-img" src="@/assets/BlendJet-2-BLACK.png" alt="BlendJet-2" />
			</div>
			<IntroList />

	</div>
	<div class="intro-btn">
		<a href="#/products/blendjet-2">GET YOURS NOW</a>
	</div>
	</div>
</template>

<script>
import IntroList from '@/components/Fitches/IntroList.vue';
export default{
	name: 'IntroBlengjed',
	components:{
		IntroList
	}
}
</script>


<style scoped>
.intro{
	background: linear-gradient(180deg,#373795 0,#1e90bb);
	padding-top: 65px;
	color: #FFF;
}
.intro-title{
  font-weight: 500;
  letter-spacing: 3.5px;
  line-height: 32px;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
	font-weight: Medium;
	font-size: 28px;
}
.intro-subtitle{
	font-weight: 500;
  letter-spacing: 3.5px;
  line-height: 32px;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
	font-weight: Medium;
	font-size: 24px;
}
.intro-desc{
	display: flex;
	justify-content: center;
  font-size: 14px;
  letter-spacing: .5px;
  line-height: 18px;
	padding-bottom: 80px;
}
.intro-desc div{
	width: 420px;
	text-align: center;
}
.intro__list {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 50px;
}
.intro__list-item {
	display: flex;
	justify-content: space-between;
	align-content: center;
}
.intro__list-item-img{
	height: 500px;
	width: auto;
}
.list-item__inner {
	display: flex;
	align-items: center;
	margin-bottom: 40px;
}
.list-item__inner__text {
	max-width: 295px;
	margin-left: 25px;
}
.list-item__inner__text-title {
	font-weight: Bold;
  font-size: 12px;
  letter-spacing: 1.75px;
  line-height: 1.17;
  text-transform: uppercase;
}
.list-item__inner__text-desc {
  font-size: 14px;
  letter-spacing: .5px;
  line-height: 1.29;
	font-style: normal;
  font-weight: 400;
}
.intro-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 50px;
}
.intro-btn a{
  background-color: #fff;
  border: none;
  border-radius: 40px;
  color: #373975;
  cursor: pointer;
  font-weight: Bold;
  font-size: 12px;
  letter-spacing: 1.75px;
  line-height: 14px;
	padding: 18px 80px;
  text-transform: uppercase;
}
.intro-btn a:hover{
	opacity: 0.9;
}
@media(max-width: 678px){
	.intro{
		padding: 30px 5px 0 5px;
	}	
	.intro-btn{
		padding-bottom: 30px;
	}
	.intro__list{
		flex-wrap: wrap;
	}
	.intro__list-item-img{
		margin-bottom: 40px;
	}
	.intro__list{
		margin-bottom: 20px;
	}
	.intro-desc{
		padding-bottom: 40px;
	}
}
@media(max-width: 400px){
	.intro-title{
		font-size: 20px;
		letter-spacing: 2.5px;
		margin-bottom: 10px;
	}
	.intro-subtitle{
		font-size: 18px;
		letter-spacing: 1.5px;
		margin-bottom: 10px;
	}
	.intro-desc{
		padding-bottom: 0;
	}
	.intro__list-item-img{
		margin-bottom: 20px;
	}
}
</style>