<template>
  <div class="intro__list-item--right">
    <div class="intro__list-item list-item__inner">
      <div class="list-item__inner__img">
        <svg width="70px" height="70px" viewBox="0 0 70 70">
          <title>Globe</title>
          <circle
            cx="35"
            cy="35"
            r="34"
            fill="transparent"
            stroke="#fff"
            stroke-width="1.5"
          ></circle>
          <path
            fill="#fff"
            d="M35 5C18.4 5 5 18.4 5 35s13.4 30 30 30 30-13.4 30-30S51.6 5 35 5zm-.7 8.7v10.8h-8.6c2.1-6.5 5.4-10.3 5.4-10.4L31 14c1.1-.2 2.2-.3 3.3-.3zm-5.1.8c-1.2 1.7-3.4 5.1-4.9 10h-7.7c2.7-4.8 7.2-8.5 12.6-10zM15.9 25.8h8c-.7 2.5-1.1 5.4-1.2 8.6h-8.8c.1-3.1.8-6 2-8.6zM14 35.7h8.8c.1 3.2.5 6.1 1.1 8.6h-7.6c-1.3-2.7-2.1-5.6-2.3-8.6zm3.1 9.8h7.2c1.2 4 2.9 7.1 4.1 9-4.7-1.6-8.7-4.8-11.3-9zm7-9.8h10.2v8.6h-9c-.7-2.6-1.2-5.4-1.2-8.6zm10.2 19.8c-1.3 0-2.6-.2-3.8-.5-1.1-1.5-3.2-4.7-4.7-9.5h8.6v10h-.1zm0-21.1H24.1c.1-3.2.5-6.1 1.2-8.6h9v8.6zm19-9.9h-7.7c-1.5-4.9-3.6-8.3-4.9-10 5.4 1.5 9.9 5.2 12.6 10zm-7.5 9.9H35.6v-8.6h9c.6 2.5 1.1 5.4 1.2 8.6zM35.6 13.7c1.1 0 2.2.2 3.2.3l-.1.1c0 .1 3.4 3.8 5.4 10.4h-8.6V13.7h.1zm0 22h10.2c-.1 3.2-.5 6.1-1.2 8.6h-9v-8.6zm0 19.8v-10h8.6c-1.5 4.7-3.6 8-4.7 9.5-1.3.3-2.6.5-3.9.5zm5.8-1c1.3-1.9 2.9-4.9 4.1-9h7.2c-2.5 4.2-6.5 7.4-11.3 9zm12.1-10.3h-7.6c.7-2.5 1.1-5.4 1.2-8.6h8.8c-.2 3.1-1 6-2.4 8.6zm-6.4-9.8c-.1-3.2-.5-6.1-1.1-8.6h8c1.2 2.6 1.9 5.5 1.9 8.6h-8.8z"
          ></path>
        </svg>
      </div>
      <div class="list-item__inner__text">
        <div class="list-item__inner__text-title">PORTABLE</div>
        <div class="list-item__inner__text-desc">
          Perfect for jetting around town. Lightweight & durably constructed.
          Stash it in your bag & drink straight from the jar.
        </div>
      </div>
    </div>
    <div class="intro__list-item list-item__inner">
      <div class="list-item__inner__img">
        <svg width="70px" height="70px" viewBox="0 0 70 70">
          <title>Power</title>
          <circle
            cx="35"
            cy="35"
            r="34"
            fill="transparent"
            stroke="#fff"
            stroke-width="1.5"
          ></circle>
          <path
            fill="#fff"
            d="M35 5c16.569 0 30 13.431 30 30 0 16.569-13.431 30-30 30C18.431 65 5 51.569 5 35 5 18.431 18.431 5 35 5Zm10.397 13h-11.42L25 35.725h6.893L26.604 52 46 29.279h-7.213L45.397 18Z"
          ></path>
        </svg>
      </div>
      <div class="list-item__inner__text">
        <div class="list-item__inner__text-title">POWERFUL</div>
        <div class="list-item__inner__text-desc">
          Big blender performance, compact size. Patented TurboJet™ technology
          powers through anything in 20 seconds flat: ice, frozen fruit, leafy
          greens & more.
        </div>
      </div>
    </div>
    <div class="intro__list-item list-item__inner">
      <div class="list-item__inner__img">
        <svg width="70px" height="70px" viewBox="0 0 70 70">
          <title>USB</title>
          <circle
            cx="35"
            cy="35"
            r="34"
            fill="transparent"
            stroke="#fff"
            stroke-width="1.5"
          ></circle>
          <path
            fill="#fff"
            d="M35 5c16.569 0 30 13.431 30 30 0 16.569-13.431 30-30 30C18.431 65 5 51.569 5 35 5 18.431 18.431 5 35 5Zm-.34 11-5.059 8.117h4.626v18.555l-5.938-4.825v-3.563a3.808 3.808 0 0 0 3.316-3.778 3.808 3.808 0 0 0-3.803-3.813A3.807 3.807 0 0 0 24 30.506a3.808 3.808 0 0 0 3.316 3.778v4.028l6.91 5.616v2.51a3.808 3.808 0 0 0-3.135 3.75A3.807 3.807 0 0 0 34.893 54c2.1 0 3.803-1.707 3.803-3.812 0-2.002-1.54-3.64-3.496-3.798v-6.147l7.373-3.503v-2.834h2.905v-6.8h-6.782v6.8H41.6v2.216l-6.4 3.041V24.117h4.626L34.66 16Z"
          ></path>
        </svg>
      </div>
      <div class="list-item__inner__text">
        <div class="list-item__inner__text-title">USB-RECHARGEABLE</div>
        <div class="list-item__inner__text-desc">
          Lasts for 15+ blends & recharges quickly. Water-resistant USB-C
          charging port. Reversible cable included.
        </div>
      </div>
    </div>
    <div class="intro__list-item list-item__inner">
      <div class="list-item__inner__img">
        <svg width="70px" height="70px" viewBox="0 0 70 70">
          <title>Drop</title>
          <circle
            cx="35"
            cy="35"
            r="34"
            fill="transparent"
            stroke="#fff"
            stroke-width="1.5"
          ></circle>
          <path
            fill="#fff"
            d="M35 5C18.4 5 5 18.4 5 35s13.4 30 30 30 30-13.4 30-30S51.6 5 35 5zm0 47c-6.1 0-11-5-11-11.1C24 31.7 35 16 35 16s11 15.7 11 24.9C46 47 41.1 52 35 52z"
          ></path>
        </svg>
      </div>
      <div class="list-item__inner__text">
        <div class="list-item__inner__text-title">SELF-CLEANING</div>
        <div class="list-item__inner__text-desc">
          Just add water, soap & blend. Rinse and you’re ready to go! The
          BlendJet 2 portable blender is the world’s most convenient blender.
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.intro__list-item {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.intro__list-item img {
  height: 500px;
  width: auto;
}
.list-item__inner {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.list-item__inner__text {
  max-width: 295px;
  margin-left: 25px;
}
.list-item__inner__text-title {
  font-weight: Bold;
  font-size: 12px;
  letter-spacing: 1.75px;
  line-height: 1.17;
  text-transform: uppercase;
}
.list-item__inner__text-desc {
  font-weight: Regular;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 1.29;
  font-style: normal;
  font-weight: 400;
}
@media(max-width: 400px){
	.list-item__inner__text{
		margin-left: 10px;
	}
	.list-item__inner__text-title{
		margin-bottom: 5px;
	}
	.list-item__inner__text-desc{
		font-size: 12px;
		line-height: 1.19;
		text-align: justify;
	}
}
</style>
