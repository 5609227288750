<template>
  <div class="feature">
    <div class="feature__text">
      <div class="feature__text-title">BLENDJET 2 FEATURES</div>
      <div class="feature__text__list">
        <div class="feature__text__list-item">
          <div class="feature__text__list-item-img">
            <svg width="70px" height="70px" viewBox="0 0 70 70">
              <title>Globe</title>
              <circle
                cx="35"
                cy="35"
                r="34"
                fill="transparent"
                stroke="#fff"
                stroke-width="1.5"
              ></circle>
              <path
                fill="#fff"
                d="M35 5C18.4 5 5 18.4 5 35s13.4 30 30 30 30-13.4 30-30S51.6 5 35 5zm-.7 8.7v10.8h-8.6c2.1-6.5 5.4-10.3 5.4-10.4L31 14c1.1-.2 2.2-.3 3.3-.3zm-5.1.8c-1.2 1.7-3.4 5.1-4.9 10h-7.7c2.7-4.8 7.2-8.5 12.6-10zM15.9 25.8h8c-.7 2.5-1.1 5.4-1.2 8.6h-8.8c.1-3.1.8-6 2-8.6zM14 35.7h8.8c.1 3.2.5 6.1 1.1 8.6h-7.6c-1.3-2.7-2.1-5.6-2.3-8.6zm3.1 9.8h7.2c1.2 4 2.9 7.1 4.1 9-4.7-1.6-8.7-4.8-11.3-9zm7-9.8h10.2v8.6h-9c-.7-2.6-1.2-5.4-1.2-8.6zm10.2 19.8c-1.3 0-2.6-.2-3.8-.5-1.1-1.5-3.2-4.7-4.7-9.5h8.6v10h-.1zm0-21.1H24.1c.1-3.2.5-6.1 1.2-8.6h9v8.6zm19-9.9h-7.7c-1.5-4.9-3.6-8.3-4.9-10 5.4 1.5 9.9 5.2 12.6 10zm-7.5 9.9H35.6v-8.6h9c.6 2.5 1.1 5.4 1.2 8.6zM35.6 13.7c1.1 0 2.2.2 3.2.3l-.1.1c0 .1 3.4 3.8 5.4 10.4h-8.6V13.7h.1zm0 22h10.2c-.1 3.2-.5 6.1-1.2 8.6h-9v-8.6zm0 19.8v-10h8.6c-1.5 4.7-3.6 8-4.7 9.5-1.3.3-2.6.5-3.9.5zm5.8-1c1.3-1.9 2.9-4.9 4.1-9h7.2c-2.5 4.2-6.5 7.4-11.3 9zm12.1-10.3h-7.6c.7-2.5 1.1-5.4 1.2-8.6h8.8c-.2 3.1-1 6-2.4 8.6zm-6.4-9.8c-.1-3.2-.5-6.1-1.1-8.6h8c1.2 2.6 1.9 5.5 1.9 8.6h-8.8z"
              ></path>
            </svg>
          </div>
          <div class="feature__text__list-item-text">
            <div class="feature__text__list-item-title">Portable</div>
            <div class="feature__text__list-item-desc">
              Perfect for jetting around town. Lightweight & durably
              constructed. Stash it in your bag & drink straight from the jar.
            </div>
          </div>
        </div>

        <div class="feature__text__list-item">
          <div class="feature__text__list-item-img">
            <svg width="70px" height="70px" viewBox="0 0 70 70">
              <title>Power</title>
              <circle
                cx="35"
                cy="35"
                r="34"
                fill="transparent"
                stroke="#fff"
                stroke-width="1.5"
              ></circle>
              <path
                fill="#fff"
                d="M35 5c16.569 0 30 13.431 30 30 0 16.569-13.431 30-30 30C18.431 65 5 51.569 5 35 5 18.431 18.431 5 35 5Zm10.397 13h-11.42L25 35.725h6.893L26.604 52 46 29.279h-7.213L45.397 18Z"
              ></path>
            </svg>
          </div>
          <div class="feature__text__list-item-text">
            <div class="feature__text__list-item-title">Powerful</div>
            <div class="feature__text__list-item-desc">
              Big blender performance, compact size. Patented TurboJet®
              technology powers through anything in 20 seconds flat: ice, frozen
              fruit, leafy greens & more.
            </div>
          </div>
        </div>

        <div class="feature__text__list-item">
          <div class="feature__text__list-item-img">
            <svg width="70px" height="70px" viewBox="0 0 70 70">
              <title>USB</title>
              <circle
                cx="35"
                cy="35"
                r="34"
                fill="transparent"
                stroke="#fff"
                stroke-width="1.5"
              ></circle>
              <path
                fill="#fff"
                d="M35 5c16.569 0 30 13.431 30 30 0 16.569-13.431 30-30 30C18.431 65 5 51.569 5 35 5 18.431 18.431 5 35 5Zm-.34 11-5.059 8.117h4.626v18.555l-5.938-4.825v-3.563a3.808 3.808 0 0 0 3.316-3.778 3.808 3.808 0 0 0-3.803-3.813A3.807 3.807 0 0 0 24 30.506a3.808 3.808 0 0 0 3.316 3.778v4.028l6.91 5.616v2.51a3.808 3.808 0 0 0-3.135 3.75A3.807 3.807 0 0 0 34.893 54c2.1 0 3.803-1.707 3.803-3.812 0-2.002-1.54-3.64-3.496-3.798v-6.147l7.373-3.503v-2.834h2.905v-6.8h-6.782v6.8H41.6v2.216l-6.4 3.041V24.117h4.626L34.66 16Z"
              ></path>
            </svg>
          </div>
          <div class="feature__text__list-item-text">
            <div class="feature__text__list-item-title">USB-Rechargeable</div>
            <div class="feature__text__list-item-desc">
              Lasts for 15+ blends & recharges quickly. Water-resistant USB-C
              charging port. Reversible cable included.
            </div>
          </div>
        </div>

        <div class="feature__text__list-item">
          <div class="feature__text__list-item-img">
            <svg width="70px" height="70px" viewBox="0 0 70 70">
              <title>Drop</title>
              <circle
                cx="35"
                cy="35"
                r="34"
                fill="transparent"
                stroke="#fff"
                stroke-width="1.5"
              ></circle>
              <path
                fill="#fff"
                d="M35 5C18.4 5 5 18.4 5 35s13.4 30 30 30 30-13.4 30-30S51.6 5 35 5zm0 47c-6.1 0-11-5-11-11.1C24 31.7 35 16 35 16s11 15.7 11 24.9C46 47 41.1 52 35 52z"
              ></path>
            </svg>
          </div>
          <div class="feature__text__list-item-text">
            <div class="feature__text__list-item-title">Self-Cleaning</div>
            <div class="feature__text__list-item-desc">
              Just add water, soap & blend. Rinse & you’re ready to go!
            </div>
          </div>
        </div>

        <div class="feature__text__list-item">
          <div class="feature__text__list-item-img">
            <svg width="70px" height="70px" viewBox="0 0 70 70">
              <title>Leaf</title>
              <circle
                cx="35"
                cy="35"
                r="34"
                fill="transparent"
                stroke="#fff"
                stroke-width="1.5"
              ></circle>
              <path
                fill="#fff"
                d="M35 5C18.4 5 5 18.4 5 35s13.4 30 30 30 30-13.4 30-30S51.6 5 35 5zM25 46.4c-.4 1-.8 2-1 3.3-.6 2.5-3.7 1.2-2.8-.6 3.5-9 9.4-15.7 16.9-21.4 0 0-10.8 5.1-16.3 15.3-.5-18.5 11.1-19.9 27.2-24-1.4 14.3-4.5 26.7-24 27.4z"
              ></path>
            </svg>
          </div>
          <div class="feature__text__list-item-text">
            <div class="feature__text__list-item-title">BPA-Free</div>
            <div class="feature__text__list-item-desc">
              Mindfully designed to help you live well.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="feature__list">
      <div class="feature__list-item">
        <div class="featured__list-item-title">THE NEXT-GEN BLENDER</div>
        <div class="feature__list-item-desc">
          BlendJet 2 serves up big blender power on the go. We created the
          BlendJet 2 portable blender so you can make anything you want,
          anywhere in the world — from a mountaintop to your kitchen countertop.
          It’s easy and convenient to use at home, at work, outdoors, at the
          gym, in the car, at the beach, on vacation or wherever the day takes
          you.
        </div>
        <div class="feature__list-item-video">
          <video
            autoplay=""
            loop=""
            muted=""
            webkit-playsinline=""
            playsinline=""
            width="400"
            height="400"
            class="KHmcoaNo"
          >
            <source
              src="https://cdn.shopify.com/s/files/1/0066/4433/4658/files/1_NEXT-GEN.mp4?v=1614333788"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <div class="feature__list-item">
        <div class="featured__list-item-title">
          Patented TurboJet Technology
        </div>
        <div class="feature__list-item-desc">
          Traditional blenders only use their blades to blend, but we invented a
          new method that makes every other blender obsolete. Our secret weapon?
          BlendJet 2’s stainless steel blades are offset from the center of the
          base, which creates a tornado effect that blasts ingredients into the
          back of the jar 275 times per second, resulting in dramatically better
          blending. This technology — combined with a more powerful motor and
          doubled battery capacity — makes BlendJet 2 five times more powerful
          than BlendJet One.
        </div>
        <div class="feature__list-item-video">
          <video
            autoplay=""
            loop=""
            muted=""
            webkit-playsinline=""
            playsinline=""
            width="400"
            height="400"
            class="KHmcoaNo"
          >
            <source
              src="https://cdn.shopify.com/s/files/1/0066/4433/4658/files/2_TURBOJET.mp4?v=1623200162"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <div class="feature__list-item">
        <div class="featured__list-item-title">Perfect for Everything</div>
        <div class="feature__list-item-desc">
          BlendJet 2 makes smoothie-bar-quality beverages, silky-smooth protein
          shakes, top-shelf mixed drinks and creamy frozen lattes, plus
          milkshakes, slushies, baby food, dips, dressings, sauces, and so much
          more. We’ll send a new recipe video straight to your inbox each week
          to inspire creativity and ensure you get the most out of your BlendJet
          2.
        </div>
        <div class="feature__list-item-video">
          <video
            autoplay=""
            loop=""
            muted=""
            webkit-playsinline=""
            playsinline=""
            width="400"
            height="400"
            class="KHmcoaNo"
          >
            <source
              src="https://cdn.shopify.com/s/files/1/0066/4433/4658/files/3_PERFECT.mp4?v=1614333788"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <div class="feature__list-item">
        <div class="featured__list-item-title">Shake Up Your Routine</div>
        <div class="feature__list-item-desc">
          BlendJet 2 is more than a blender — it’s a way of life. It helps you
          live healthier while keeping your energy level sky-high. Make
          breakfast a breeze, reinvent lunch, fuel your workout or chill with a
          frozen margarita, all while saving time and money.
        </div>
        <div class="feature__list-item-video">
          <video
            autoplay=""
            loop=""
            muted=""
            webkit-playsinline=""
            playsinline=""
            width="400"
            height="400"
            class="KHmcoaNo"
          >
            <source
              src="https://cdn.shopify.com/s/files/1/0066/4433/4658/files/4_SHAKE-UP-ROUTINE.mp4?v=1614333788"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <div class="feature__list-item">
        <div class="featured__list-item-title">USB-C Rechargeable</div>
        <div class="feature__list-item-desc">
          Get 15+ blends from just one hour of charging with any USB port. The
          BlendJet 2 portable blender is equipped with a water-resistant USB-C
          port that makes it easier than ever to power up. And the included
          cable is smartly reversible, so there’s no wrong way to plug it in.
        </div>
        <div class="feature__list-item-video">
          <video
            autoplay=""
            loop=""
            muted=""
            webkit-playsinline=""
            playsinline=""
            width="400"
            height="400"
            class="KHmcoaNo"
          >
            <source
              src="https://cdn.shopify.com/videos/c/o/v/41cd41a01cd54533a189e73c5f1660eb.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <div class="feature__list-item">
        <div class="featured__list-item-title">SELF-CLEANING</div>
        <div class="feature__list-item-desc">
          Say goodbye to the most annoying part of blending. Bulky kitchen
          blenders feel impossible to clean — especially when you’re in a hurry
          — but BlendJet 2 cleans itself. Just add a drop of soap, a bit of
          water, blend, and you’re ready for your next blended creation.
        </div>
        <div class="feature__list-item-video">
          <video
            autoplay=""
            loop=""
            muted=""
            webkit-playsinline=""
            playsinline=""
            width="400"
            height="400"
            class="KHmcoaNo"
          >
            <source
              src="https://cdn.shopify.com/videos/c/o/v/f3033453a5e54c7fa75f3788b2cf0f30.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <div class="feature__list-item">
        <div class="featured__list-item-title">COLLECTABLE COLORS</div>
        <div class="feature__list-item-desc">
          BlendJet 2 comes in tons of vibrant colors and patterns with a design
          that complements your style. It looks great on your desk or countertop
          and is a positive reminder to enjoy something delicious and nutritious
          every day.
        </div>
        <div class="feature__list-item-video">
          <video
            autoplay=""
            loop=""
            muted=""
            webkit-playsinline=""
            playsinline=""
            width="400"
            height="400"
            class="KHmcoaNo"
          >
            <source
              src="https://cdn.shopify.com/videos/c/o/v/31eb651a846c497392d1cb929b11a3d5.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <div class="feature__list-item">
        <div class="featured__list-item-title">
          FOOD PROCESSING WITH PULSE MODE
        </div>
        <div class="feature__list-item-desc">
          With a double-press of the power button, your BlendJet 2 portable
          blender transforms into a powerful food processor that pulverizes
          veggies, fruits, nuts and more. You can pulse your way to tasty
          guacamole, pesto, salsa and hummus in mere seconds.
        </div>
        <div class="feature__list-item-video">
          <video
            autoplay=""
            loop=""
            muted=""
            webkit-playsinline=""
            playsinline=""
            width="400"
            height="400"
            class="KHmcoaNo"
          >
            <source
              src="https://cdn.shopify.com/videos/c/o/v/31eb651a846c497392d1cb929b11a3d5.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <div class="feature__list-item">
        <div class="featured__list-item-title">FORGET THE MEASURING CUP</div>
        <div class="feature__list-item-desc">
          Measurement markings on the jar make it easy to follow our
          mouth-watering recipes or to create your own. With its 16 oz capacity,
          BlendJet 2 holds 33% more than the original BlendJet in a sleek and
          compact design.
        </div>
        <div class="feature__list-item-video">
          <video
            autoplay=""
            loop=""
            muted=""
            webkit-playsinline=""
            playsinline=""
            width="400"
            height="400"
            class="KHmcoaNo"
          >
            <source
              src="https://cdn.shopify.com/videos/c/o/v/71a06cd84d7c4102b2197cd60bedad75.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <div class="feature__list-item">
        <div class="featured__list-item-title">
          WATER-RESISTANT & READY FOR ADVENTURE
        </div>
        <div class="feature__list-item-desc">
          BlendJet 2 is also water-resistant, so you don’t have to worry about
          getting liquid in the USB-C port or accidentally submerging your
          BlendJet 2 in the sink or pool. It’s the ideal blender for mermaids.
        </div>
        <div class="feature__list-item-video">
          <video
            autoplay=""
            loop=""
            muted=""
            webkit-playsinline=""
            playsinline=""
            width="400"
            height="400"
            class="KHmcoaNo"
          >
            <source
              src="https://cdn.shopify.com/videos/c/o/v/85ee32a82db04046b6bcfcfd751dc7f3.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <div class="feature__list-item">
        <div class="featured__list-item-title">QUIET ENOUGH FOR A LIBRARY</div>
        <div class="feature__list-item-desc">
          We engineered BlendJet 2 to deliver incredible power without the
          jet-engine sound made by clunky kitchen blenders. In fact, the
          BlendJet 2 portable blender is so quiet, you can use it during
          late-night study sessions or early in the morning without waking up
          your household.
        </div>
        <div class="feature__list-item-video">
          <video
            autoplay=""
            loop=""
            muted=""
            webkit-playsinline=""
            playsinline=""
            width="400"
            height="400"
            class="KHmcoaNo"
          >
            <source
              src="https://cdn.shopify.com/videos/c/o/v/91928b760d604568aee0d201a436e1d8.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <div class="feature__list-item">
        <div class="featured__list-item-title">LOCK YOUR BLENDER</div>
        <div class="feature__list-item-desc">
          Shift into Lock Mode to prevent accidental blending, and confidently
          toss your BlendJet 2 portable blender into your backpack or purse, or
          sip straight from the jar.
        </div>
        <div class="feature__list-item-video">
          <video
            autoplay=""
            loop=""
            muted=""
            webkit-playsinline=""
            playsinline=""
            width="400"
            height="400"
            class="KHmcoaNo"
          >
            <source
              src="https://cdn.shopify.com/videos/c/o/v/41cd41a01cd54533a189e73c5f1660eb.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <div class="feature__list-item">
        <div class="featured__list-item-title">NO FRIDGE? NO PROBLEM!</div>
        <div class="feature__list-item-desc">
          Make healthy happen on the run with our nutrient-rich JetPack®
          Ready-to-Blend Smoothies. Simply add milk or water to enjoy a creamy,
          delicious smoothie whenever, wherever. JetPacks are proudly made in
          California with only the highest-quality ingredients and no artificial
          anything. Add JetPack to your order today.
        </div>
        <div class="feature__list-item-video">
          <video
            autoplay=""
            loop=""
            muted=""
            webkit-playsinline=""
            playsinline=""
            width="400"
            height="400"
            class="KHmcoaNo"
          >
            <source
              src="https://cdn.shopify.com/videos/c/o/v/59f120ff6e1f4dc7a911539d2448f29c.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <div class="feature__list-item">
        <div class="featured__list-item-title">
          ON A MISSION TO IMPROVE LIVES
        </div>
        <div class="feature__list-item-desc">
          BlendJet was born after our co-founder suffered a debilitating injury
          and smoothies and shakes became a key part of his recovery process.
          After he (thankfully!) made a full recovery, he felt compelled to find
          a way to help others live longer and healthier lives. Our mission is
          to make the healthy choice the most convenient choice for all.
        </div>
        <div class="feature__list-item-video">
          <img
            src="//images.ctfassets.net/strhx3d94c40/5DLdkUNBkZyGgOgNdCEWno/067c9346106bcd4eb7d68d390d0a83f6/About_us_hero_image_TWO.jpg"
            alt="on a mission"
            loading="lazy"
            height="500"
            class="MjRUSbEy"
          />
        </div>
      </div>

      <div class="feature__list-item">
        <div class="featured__list-item-title">WORLDWIDE SHIPPING</div>
        <div class="feature__list-item-desc">
          We offer worldwide shipping from our headquarters just east of San
          Francisco in Benicia, California. We take great pride in handling all
          of our own research and development, design, manufacturing, shipping
          and customer service.
        </div>
        <div class="feature__list-item-video">
          <video
            autoplay=""
            loop=""
            muted=""
            webkit-playsinline=""
            playsinline=""
            width="400"
            height="400"
            class="KHmcoaNo"
          >
            <source
              src="https://cdn.shopify.com/videos/c/o/v/71f0169b082a4424b0eec37728ddbed0.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <div class="feature__list-item">
        <div class="featured__list-item-title">30-DAY MONEY BACK GUARANTEE</div>
        <div class="feature__list-item-desc">
          Don’t be fooled by imitators. Nothing else comes close to the quality,
          power and innovation of the BlendJet 2 portable blender. We guarantee
          you’ll love it, or your money back.
        </div>
        <div class="feature__list-item-video">
          <video
            autoplay=""
            loop=""
            muted=""
            webkit-playsinline=""
            playsinline=""
            width="400"
            height="400"
            class="KHmcoaNo"
          >
            <source
              src="https://cdn.shopify.com/videos/c/o/v/13b0eb04d814416da99f4d70ef8e10eb.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <div class="feature__list-item">
        <div class="featured__list-item-title">DETAILS & SPECS</div>
        <div class="feature__list-item-desc">
          Blend your favorite smoothies, shakes, margaritas, and more without
          the limitations of a regular blender - whenever, wherever you want!
        </div>
        <div class="feature__list-item-img">
          <v-img src="@/assets/reddot-winner.jpg" alt="reddot" />
        </div>
        <ul class="feature__list-item__list">
          <li>Compact Size: 9” x 3” (230mm x 76mm)</li>
          <li>Product Weight: 1.34 lb (.61 kg)</li>
          <li>Jar w/ Measurement Markings: 16 oz (475 mL)</li>
          <li>15+ Blends Per 1 Hour Charge</li>
          <li>Water-resistant USB-C Port</li>
          <li>4000 mAh Rechargeable Battery</li>
          <li>5V Electric Motor Spins 275 Times Per Second</li>
          <li>6-Point Stainless Steel Blade</li>
          <li>Durable Built-In Carrying Strap</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeaturedBLend",
  components: {},
};
</script>

<style scoped>
.feature__text-title {
  font-size: 24px;
  letter-spacing: 4px;
  line-height: 1.17;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 50px;
}
.feature__text__list {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feature__text__list-item {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  max-width: 460px;
  width: 100%;
}
.feature__text__list-item-img {
  margin-right: 25px;
}

.feature__text__list-item-title {
  font-weight: Bold;
  font-size: 12px;
  letter-spacing: 1.75px;
  line-height: 1.17;
  margin-bottom: 8px;
  text-transform: uppercase;
}
.feature__text__list-item-desc {
  font-weight: Regular;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 1.29;
}
.feature {
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-flow: row nowrap;
}
.feature__text {
  width: 50%;
  background-color: #33499d;
  position: sticky;
  top: 100px;
  color: #fff;
  height: 100dvh;
}

.feature__list {
  width: 50%;
  padding-top: 50px;
}
.feature__list-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.featured__list-item-title {
  color: #373975;
  font-weight: Bold;
  font-size: 24px;
  letter-spacing: 2.5px;
  line-height: 1.22;
  margin-bottom: 15px;
  padding: 0 10px;
  text-align: center;
  text-transform: uppercase;
}
.feature__list-item-desc {
  color: #999;
  font-family: Regular;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 1.29;
  margin-bottom: 35px;
  padding: 0 20px;
  font-family: "Roboto", sans-serif;
  max-width: 520px;
  width: 100%;
}
.feature__list-item-video {
  margin-bottom: 50px;
}
video {
  border-radius: 5px;
}

.feature__list-item__list {
  padding-bottom: 50px;
}
.feature__list-item__list li {
  list-style-type: square;
  margin-bottom: 15px;
  color: #999;
  font-family: Regular;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 1.29;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 768px) {
  .feature__text {
    width: 100%;
    background-color: #33499d;
    position: static;
    color: #fff;
    height: auto;
    padding: 0 5px;
    margin-bottom: 20px;
  }
  .feature {
    flex-wrap: wrap;
    justify-content: center;
  }
  .feature__list {
    width: 100%;
    padding: 0 5px;
  }
}
@media (max-width: 500px) {
  .feature img,
  .feature video {
    width: min(100%, 320px);
    height: auto;
  }
  .feature__list-item-desc {
    text-align: justify;
  }
  .feature__list-item-video {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 400px) {
  .feature__list-item__list li[data-v-fa418212] {
    margin-bottom: 12px;
    font-size: 12px;
    margin-left: 5px;
  }
  .feature__text__list-item-desc {
    font-size: 12px;
    text-align: justify;
  }
  .feature__text__list-item-img {
    margin-right: 15px;
  }
}
</style>
