<template>
  <div class="container anatomy">
    <div class="anatomy-title title">ANATOMY OF AWESOME</div>
    <div class="anatomy-img">
      <v-img class="anatomy-v-img" src="@/assets/Anatomy.png" alt="guide" />
    </div>
    <div class="anatomy-guide">
      <div class="anatomy-guide__item">
        <div class="anatomy-guide__item-text">
          <div class="anatomy-guide-title title">YOUR FIRST BLEND...</div>
          <div class="anatomy-guide-desc">
            Make whatever you crave, wherever you are. Here’s how:
          </div>
          <ul class="anatomy-guide__list">
            <li>
              Charge your BlendJet with the included USB cable for about 1 hour
              or until the light ring shows a solid blue circle.
            </li>
            <li>
              Ensure the arrows on the rear of the jar and base are closely
              aligned.
            </li>
            <li>
              Clean your BlendJet prior to first use: add water, a drop of soap,
              blend and rinse. (Deep clean as needed - see "Cleaning" section
              for details.)
            </li>
            <li>
              Add liquid first, then throw in your solids. Leave a little room
              at the top.
            </li>
            <li>
              Tighten the lid and press the power button to blend for one
              20-second cycle. Repeat as needed for thicker blends.
            </li>
            <li>
              Enjoy your blended creation and clean immediately after each use.
            </li>
          </ul>
        </div>
        <div class="anatomy-guide__item-video">
          <video
            autoplay=""
            loop=""
            muted=""
            webkit-playsinline=""
            playsinline=""
          >
            <source
              src="https://cdn.shopify.com/s/files/1/0066/4433/4658/files/FirstBlend_Blend_1.mp4?v=1618601438"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <div class="anatomy-guide__item">
        <div class="anatomy-guide__item-text">
          <div class="anatomy-guide-title title">CHARGING</div>
          <ul class="anatomy-guide__list">
            <li>
              Using the included USB-C cable, you can charge your BlendJet with
              most USB ports, from your phone charger, to your computer, to the
              USB port in your car.
            </li>
            <li>
              Your BlendJet takes about 1.5 hours to fully charge, and lasts for
              15+ blends.
            </li>
            <li>
              The light ring around the power button indicates battery level
              while charging. As your BlendJet charges, the lights will change
              from purple to blue. When the light around the power button forms
              a complete blue circle, your BlendJet is fully charged.
            </li>
            <li>
              The circle turns purple (instead of blue) while blending or
              pulsing whenever the battery is getting low.
            </li>
            <li>
              The circle will flash red and purple when the battery is dead and
              it's time to recharge. Do not charge your BlendJet when it's wet.
            </li>
          </ul>
        </div>
        <div class="anatomy-guide__item-video">
          <video
            autoplay=""
            loop=""
            muted=""
            webkit-playsinline=""
            playsinline=""
          >
            <source
              src="//videos.ctfassets.net/strhx3d94c40/7KrcmJon0PuZ7oRCTA5vUj/71833d6876cb4cdd036739c922fe0fdd/100__50__Low_Battery_No_Battery_LED_Circle_Meanings_1.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    </div>

    <div class="tips">
      <div class="tips-title title">A MODE FOR EVERY MOOD</div>
      <div class="tips-desc">
        <a href="#">BlendJet 2</a> has three easy-to-use modes: Blend Mode,
        Pulse Mode and Lock Mode.
      </div>
      <div class="tips__mode">
        <div class="tips__mode-title title" @click="readMore($event)">
          <div class="tips__mode-title-icon">
            <svg width="29px" height="29px" viewBox="0 0 29 29">
              <title>Group 16</title>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-185.000000, -2477.000000)">
                  <g transform="translate(160.000000, 2396.000000)">
                    <g transform="translate(0.000000, 62.000000)">
                      <g transform="translate(25.000000, 19.000000)">
                        <circle
                          stroke="#1E90BB"
                          stroke-width="1.5"
                          cx="14.5"
                          cy="14.5"
                          r="13.75"
                        ></circle>
                        <path
                          d="M21.7972536,12.3415068 C21.1955513,9.7901465 19.3145906,7.44931841 16.7193616,6.73125321 C14.8902299,6.22533143 12.9417835,6.59886497 11.2462734,7.37359126 C10.7279834,7.61043999 10.221301,7.88300401 9.75160072,8.20497864 C12.1913428,7.22347965 15.1258898,7.60399513 17.1666566,9.27912254 C19.1107839,10.8744948 19.9451768,13.4919149 19.0565254,15.8765144 C18.5420146,17.2573264 17.5337787,18.4579507 16.1487027,19.0320806 C14.8243638,19.5815052 13.2751627,19.5149083 12.0615004,18.7275608 C11.0246505,18.0551467 10.3114619,16.8819131 10.240197,15.6391286 C10.1621836,14.2816792 10.9336798,12.9776685 12.2766447,12.5772814 C13.5200007,12.2067017 15.539442,12.7110123 15.1123927,14.3968812 C14.7501296,15.8254925 12.694246,15.8297891 12.3214552,14.4054743 C11.8387976,15.0598965 12.0126407,15.989568 12.567103,16.5387241 C13.1318231,17.0980845 13.956768,17.3357389 14.7320434,17.1748858 C15.9910562,16.9138689 16.929107,15.8117972 17.141282,14.5733093 C17.4074455,13.0155321 16.5077265,11.5678547 15.1086135,10.8992001 C13.735685,10.2431667 12.166778,10.4389294 10.8783415,11.1835797 C7.93569621,12.884218 7.09698426,16.8513 9.07458444,19.5978859 C10.9215324,22.162136 14.5816854,22.6409356 17.3564263,21.4008365 C20.7717413,19.8747465 22.6448736,15.9466023 21.7972536,12.3415068"
                          fill="#437CBF"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="tips__mode-title__inner">blend mode</div>
        </div>
        <div class="tips__mode-text">
          <div class="tips__mode-text-item">
            Perfect for smoothies, shakes and mixed drinks.
          </div>
          <div class="tips__mode-text-item">
            <span>WHAT IT DOES:</span>
            Allows you to achieve a totally smooth consistency.
          </div>
          <div class="tips__mode-text-item">
            <span>HOW IT WORKS:</span>
            Press power button once to start a 20-second blend cycle, or press
            it again to stop mid-cycle.
          </div>
          <video
            autoplay=""
            loop=""
            muted=""
            height="350"
            webkit-playsinline=""
            playsinline=""
          >
            <source
              src="https://cdn.shopify.com/s/files/1/0066/4433/4658/files/RegularBlend_Updated_1.mp4?v=1618601986"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
      <div class="tips__mode">
        <div class="tips__mode-title title" @click="readMore($event)">
          <div class="tips__mode-title-icon">
            <svg width="32px" height="26px" viewBox="0 0 32 26">
              <title>Group 11</title>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-185.000000, -2878.000000)">
                  <g transform="translate(160.000000, 2396.000000)">
                    <g transform="translate(0.000000, 62.000000)">
                      <g transform="translate(0.000000, 406.000000)">
                        <g transform="translate(25.000000, 14.000000)">
                          <g
                            fill="#1E90BB"
                            stroke="#1E90BB"
                            stroke-linecap="round"
                            stroke-width="0.5"
                          >
                            <circle
                              transform="translate(4.930117, 22.558075) rotate(12.000000) translate(-4.930117, -22.558075) "
                              cx="4.930117"
                              cy="22.5580752"
                              r="1"
                            ></circle>
                            <circle
                              cx="6.61969697"
                              cy="24.7727273"
                              r="1"
                            ></circle>
                            <circle
                              transform="translate(3.345955, 20.234952) rotate(23.000000) translate(-3.345955, -20.234952) "
                              cx="3.34595533"
                              cy="20.2349522"
                              r="1"
                            ></circle>
                            <circle
                              transform="translate(2.261863, 17.640494) rotate(35.000000) translate(-2.261863, -17.640494) "
                              cx="2.26186278"
                              cy="17.6404942"
                              r="1"
                            ></circle>
                            <circle
                              transform="translate(1.722222, 14.880919) rotate(46.000000) translate(-1.722222, -14.880919) "
                              cx="1.72222222"
                              cy="14.8809187"
                              r="1"
                            ></circle>
                            <circle
                              transform="translate(1.749127, 12.069203) rotate(58.000000) translate(-1.749127, -12.069203) "
                              cx="1.7491266"
                              cy="12.069203"
                              r="1"
                            ></circle>
                            <circle
                              transform="translate(2.341474, 9.320459) rotate(70.000000) translate(-2.341474, -9.320459) "
                              cx="2.34147446"
                              cy="9.3204591"
                              r="1"
                            ></circle>
                            <circle
                              transform="translate(3.475015, 6.747221) rotate(81.000000) translate(-3.475015, -6.747221) "
                              cx="3.47501499"
                              cy="6.7472209"
                              r="1"
                            ></circle>
                            <circle
                              transform="translate(5.103341, 4.454837) rotate(93.000000) translate(-5.103341, -4.454837) "
                              cx="5.10334092"
                              cy="4.45483708"
                              r="1"
                            ></circle>
                            <circle
                              transform="translate(7.159788, 2.537158) rotate(105.000000) translate(-7.159788, -2.537158) "
                              cx="7.1597884"
                              cy="2.53715809"
                              r="1"
                            ></circle>
                            <g transform="translate(22.000000, 0.310656)">
                              <circle
                                transform="translate(1.574074, 1.574074) rotate(174.000000) translate(-1.574074, -1.574074) "
                                cx="1.57407407"
                                cy="1.57407407"
                                r="1"
                              ></circle>
                              <circle
                                transform="translate(3.781042, 3.316417) rotate(186.000000) translate(-3.781042, -3.316417) "
                                cx="3.78104228"
                                cy="3.3164174"
                                r="1"
                              ></circle>
                              <circle
                                transform="translate(5.592103, 5.467354) rotate(197.000000) translate(-5.592103, -5.467354) "
                                cx="5.59210259"
                                cy="5.46735428"
                                r="1"
                              ></circle>
                              <circle
                                transform="translate(6.933110, 7.938825) rotate(209.000000) translate(-6.933110, -7.938825) "
                                cx="6.93310998"
                                cy="7.93882513"
                                r="1"
                              ></circle>
                              <circle
                                transform="translate(7.749163, 10.629648) rotate(221.000000) translate(-7.749163, -10.629648) "
                                cx="7.74916344"
                                cy="10.6296476"
                                r="1"
                              ></circle>
                              <circle
                                transform="translate(8.006854, 13.429659) rotate(232.000000) translate(-8.006854, -13.429659) "
                                cx="8.00685365"
                                cy="13.4296592"
                                r="1"
                              ></circle>
                              <circle
                                transform="translate(7.695631, 16.224227) rotate(244.000000) translate(-7.695631, -16.224227) "
                                cx="7.69563075"
                                cy="16.224227"
                                r="1"
                              ></circle>
                              <circle
                                transform="translate(6.828236, 18.898941) rotate(255.000000) translate(-6.828236, -18.898941) "
                                cx="6.82823624"
                                cy="18.8989411"
                                r="1"
                              ></circle>
                              <circle
                                transform="translate(5.440181, 21.344298) rotate(267.000000) translate(-5.440181, -21.344298) "
                                cx="5.44018134"
                                cy="21.3442985"
                                r="1"
                              ></circle>
                              <circle
                                transform="translate(3.588293, 23.460186) rotate(279.000000) translate(-3.588293, -23.460186) "
                                cx="3.5882932"
                                cy="23.4601858"
                                r="1"
                              ></circle>
                            </g>
                          </g>
                          <path
                            d="M22.7972536,11.3415068 C22.1955513,8.7901465 20.3145906,6.44931841 17.7193616,5.73125321 C15.8902299,5.22533143 13.9417835,5.59886497 12.2462734,6.37359126 C11.7279834,6.61043999 11.221301,6.88300401 10.7516007,7.20497864 C13.1913428,6.22347965 16.1258898,6.60399513 18.1666566,8.27912254 C20.1107839,9.87449477 20.9451768,12.4919149 20.0565254,14.8765144 C19.5420146,16.2573264 18.5337787,17.4579507 17.1487027,18.0320806 C15.8243638,18.5815052 14.2751627,18.5149083 13.0615004,17.7275608 C12.0246505,17.0551467 11.3114619,15.8819131 11.240197,14.6391286 C11.1621836,13.2816792 11.9336798,11.9776685 13.2766447,11.5772814 C14.5200007,11.2067017 16.539442,11.7110123 16.1123927,13.3968812 C15.7501296,14.8254925 13.694246,14.8297891 13.3214552,13.4054743 C12.8387976,14.0598965 13.0126407,14.989568 13.567103,15.5387241 C14.1318231,16.0980845 14.956768,16.3357389 15.7320434,16.1748858 C16.9910562,15.9138689 17.929107,14.8117972 18.141282,13.5733093 C18.4074455,12.0155321 17.5077265,10.5678547 16.1086135,9.89920008 C14.735685,9.24316669 13.166778,9.43892942 11.8783415,10.1835797 C8.93569621,11.884218 8.09698426,15.8513 10.0745844,18.5978859 C11.9215324,21.162136 15.5816854,21.6409356 18.3564263,20.4008365 C21.7717413,18.8747465 23.6448736,14.9466023 22.7972536,11.3415068"
                            fill="#437CBF"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="tips__mode-title__inner">Pulse Mode</div>
        </div>
        <div class="tips__mode-text">
          <div class="tips__mode-text-item">
            Perfect for guacamole, salsa and hummus.
          </div>
          <div class="tips__mode-text-item">
            <span>WHAT IT DOES:</span>
            Allows you to achieve a chunkier texture.
          </div>
          <div class="tips__mode-text-item">
            <span>HOW IT WORKS:</span>
            To enter Pulse Mode, double-press the power button. Blue lights will
            flash left to right 3 times and the swirl will stay illuminated
            while Pulse Mode is active. Press and hold the power button
            repeatedly to pulse. Pulse Mode will automatically end after 5
            seconds of inactivity.
          </div>
          <video
            autoplay=""
            loop=""
            muted=""
            height="350"
            webkit-playsinline=""
            playsinline=""
          >
            <source
              src="//videos.ctfassets.net/strhx3d94c40/6YP4EhrROV0TVQnySCajpl/e8583cebc173b1f72d2d5d7380cde336/PulseMode_1.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <div class="tips__mode">
        <div class="tips__mode-title title" @click="readMore($event)">
          <div class="tips__mode-title-icon">
            <svg width="29px" height="29px" viewBox="0 0 29 29">
              <title>Group 14</title>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-185.000000, -3014.000000)">
                  <g transform="translate(160.000000, 2396.000000)">
                    <g transform="translate(0.000000, 62.000000)">
                      <g transform="translate(0.000000, 544.000000)">
                        <g transform="translate(25.000000, 12.000000)">
                          <circle
                            stroke="#7951A2"
                            stroke-width="1.5"
                            cx="14.5"
                            cy="14.5"
                            r="13.75"
                          ></circle>
                          <path
                            d="M21.7972536,12.3415068 C21.1955513,9.7901465 19.3145906,7.44931841 16.7193616,6.73125321 C14.8902299,6.22533143 12.9417835,6.59886497 11.2462734,7.37359126 C10.7279834,7.61043999 10.221301,7.88300401 9.75160072,8.20497864 C12.1913428,7.22347965 15.1258898,7.60399513 17.1666566,9.27912254 C19.1107839,10.8744948 19.9451768,13.4919149 19.0565254,15.8765144 C18.5420146,17.2573264 17.5337787,18.4579507 16.1487027,19.0320806 C14.8243638,19.5815052 13.2751627,19.5149083 12.0615004,18.7275608 C11.0246505,18.0551467 10.3114619,16.8819131 10.240197,15.6391286 C10.1621836,14.2816792 10.9336798,12.9776685 12.2766447,12.5772814 C13.5200007,12.2067017 15.539442,12.7110123 15.1123927,14.3968812 C14.7501296,15.8254925 12.694246,15.8297891 12.3214552,14.4054743 C11.8387976,15.0598965 12.0126407,15.989568 12.567103,16.5387241 C13.1318231,17.0980845 13.956768,17.3357389 14.7320434,17.1748858 C15.9910562,16.9138689 16.929107,15.8117972 17.141282,14.5733093 C17.4074455,13.0155321 16.5077265,11.5678547 15.1086135,10.8992001 C13.735685,10.2431667 12.166778,10.4389294 10.8783415,11.1835797 C7.93569621,12.884218 7.09698426,16.8513 9.07458444,19.5978859 C10.9215324,22.162136 14.5816854,22.6409356 17.3564263,21.4008365 C20.7717413,19.8747465 22.6448736,15.9466023 21.7972536,12.3415068"
                            fill="#7951A2"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="tips__mode-title__inner">lock mode</div>
        </div>
        <div class="tips__mode-text">
          <div class="tips__mode-text-item">
            Perfect for sipping and travel.
          </div>
          <div class="tips__mode-text-item">
            <span>WHAT IT DOES:</span>
            Allows you to drink straight from your BlendJet, or toss it in your
            bag, without worrying about it turning on by mistake.
          </div>
          <div class="tips__mode-text-item">
            <span>HOW IT WORKS:</span>
            To lock, you must be in Blend Mode. Make sure the lid is secured,
            then hold down the power button for 3 seconds while purple lights
            illuminate clockwise to form a complete circle. The purple circle
            will flash 3 times to indicate Lock Mode is active.
          </div>
          <div class="tips__mode-text-item">
            To unlock, hold down the power button for three seconds until the
            purple lights disappear. A blue circle will flash 3 times indicating
            your BlendJet is unlocked and ready to blend again.
          </div>
          <video
            autoplay=""
            loop=""
            muted=""
            height="350"
            webkit-playsinline=""
            playsinline=""
          >
            <source
              src="//videos.ctfassets.net/strhx3d94c40/5zcKndACwbRWoc0v8mWAva/30826e8186ff7164a7d7abb9daa7928a/LockMode_Updated_1.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
      <div class="tips-title title">TROUBLESHOOTING</div>
      <div class="tips__mode">
        <div class="tips__mode-title title" @click="readMore($event)">
          <div class="tips__mode-title-icon">
            <svg width="31px" height="31px" viewBox="0 0 31 31">
              <title>Group 11</title>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g
                  transform="translate(-185.000000, -3216.000000)"
                  fill="#E7252C"
                >
                  <g transform="translate(160.000000, 3137.000000)">
                    <g transform="translate(0.000000, 62.000000)">
                      <g transform="translate(25.000000, 17.000000)">
                        <g
                          stroke="#E7252C"
                          stroke-linecap="round"
                          stroke-width="0.5"
                        >
                          <circle
                            transform="translate(4.930117, 24.558075) rotate(12.000000) translate(-4.930117, -24.558075) "
                            cx="4.930117"
                            cy="24.5580752"
                            r="1"
                          ></circle>
                          <circle cx="6.775" cy="26.575" r="1"></circle>
                          <circle
                            transform="translate(3.345955, 22.234952) rotate(23.000000) translate(-3.345955, -22.234952) "
                            cx="3.34595533"
                            cy="22.2349522"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(2.261863, 19.640494) rotate(35.000000) translate(-2.261863, -19.640494) "
                            cx="2.26186278"
                            cy="19.6404942"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(1.722222, 16.880919) rotate(46.000000) translate(-1.722222, -16.880919) "
                            cx="1.72222222"
                            cy="16.8809187"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(1.749127, 14.069203) rotate(58.000000) translate(-1.749127, -14.069203) "
                            cx="1.7491266"
                            cy="14.069203"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(2.341474, 11.320459) rotate(70.000000) translate(-2.341474, -11.320459) "
                            cx="2.34147446"
                            cy="11.3204591"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(3.475015, 8.747221) rotate(81.000000) translate(-3.475015, -8.747221) "
                            cx="3.47501499"
                            cy="8.7472209"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(5.103341, 6.454837) rotate(93.000000) translate(-5.103341, -6.454837) "
                            cx="5.10334092"
                            cy="6.45483708"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(7.159788, 4.537158) rotate(105.000000) translate(-7.159788, -4.537158) "
                            cx="7.1597884"
                            cy="4.53715809"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(9.560166, 3.072694) rotate(116.000000) translate(-9.560166, -3.072694) "
                            cx="9.56016622"
                            cy="3.07269394"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(12.206203, 2.121400) rotate(128.000000) translate(-12.206203, -2.121400) "
                            cx="12.2062026"
                            cy="2.12139995"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(14.989569, 1.722222) rotate(139.000000) translate(-14.989569, -1.722222) "
                            cx="14.9895686"
                            cy="1.72222222"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(17.796313, 1.891503) rotate(151.000000) translate(-17.796313, -1.891503) "
                            cx="17.7963128"
                            cy="1.89150313"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(20.511527, 2.622312) rotate(163.000000) translate(-20.511527, -2.622312) "
                            cx="20.5115267"
                            cy="2.62231231"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(23.024049, 3.884730) rotate(174.000000) translate(-23.024049, -3.884730) "
                            cx="23.0240493"
                            cy="3.88473032"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(25.231018, 5.627074) rotate(186.000000) translate(-25.231018, -5.627074) "
                            cx="25.2310175"
                            cy="5.62707365"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(27.042078, 7.778011) rotate(197.000000) translate(-27.042078, -7.778011) "
                            cx="27.0420778"
                            cy="7.77801054"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(28.383085, 10.249481) rotate(209.000000) translate(-28.383085, -10.249481) "
                            cx="28.3830852"
                            cy="10.2494814"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(29.199139, 12.940304) rotate(221.000000) translate(-29.199139, -12.940304) "
                            cx="29.1991387"
                            cy="12.9403039"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(29.456829, 15.740315) rotate(232.000000) translate(-29.456829, -15.740315) "
                            cx="29.4568289"
                            cy="15.7403154"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(29.145606, 18.534883) rotate(244.000000) translate(-29.145606, -18.534883) "
                            cx="29.145606"
                            cy="18.5348832"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(28.278211, 21.209597) rotate(255.000000) translate(-28.278211, -21.209597) "
                            cx="28.2782115"
                            cy="21.2095974"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(26.890157, 23.654955) rotate(267.000000) translate(-26.890157, -23.654955) "
                            cx="26.8901566"
                            cy="23.6549547"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(25.038268, 25.770842) rotate(279.000000) translate(-25.038268, -25.770842) "
                            cx="25.0382684"
                            cy="25.770842"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(22.798364, 27.470635) rotate(290.000000) translate(-22.798364, -27.470635) "
                            cx="22.7983636"
                            cy="27.4706347"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(20.262144, 28.684743) rotate(302.000000) translate(-20.262144, -28.684743) "
                            cx="20.2621439"
                            cy="28.684743"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(17.533443, 29.363461) rotate(314.000000) translate(-17.533443, -29.363461) "
                            cx="17.5334427"
                            cy="29.3634611"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(14.723973, 29.479002) rotate(325.000000) translate(-14.723973, -29.479002) "
                            cx="14.7239731"
                            cy="29.4790023"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(11.948755, 29.026636) rotate(337.000000) translate(-11.948755, -29.026636) "
                            cx="11.9487553"
                            cy="29.0266363"
                            r="1"
                          ></circle>
                          <circle
                            transform="translate(9.321407, 28.024883) rotate(348.000000) translate(-9.321407, -28.024883) "
                            cx="9.32140694"
                            cy="28.0248831"
                            r="1"
                          ></circle>
                        </g>
                        <path
                          d="M22.7972536,13.3415068 C22.1955513,10.7901465 20.3145906,8.44931841 17.7193616,7.73125321 C15.8902299,7.22533143 13.9417835,7.59886497 12.2462734,8.37359126 C11.7279834,8.61043999 11.221301,8.88300401 10.7516007,9.20497864 C13.1913428,8.22347965 16.1258898,8.60399513 18.1666566,10.2791225 C20.1107839,11.8744948 20.9451768,14.4919149 20.0565254,16.8765144 C19.5420146,18.2573264 18.5337787,19.4579507 17.1487027,20.0320806 C15.8243638,20.5815052 14.2751627,20.5149083 13.0615004,19.7275608 C12.0246505,19.0551467 11.3114619,17.8819131 11.240197,16.6391286 C11.1621836,15.2816792 11.9336798,13.9776685 13.2766447,13.5772814 C14.5200007,13.2067017 16.539442,13.7110123 16.1123927,15.3968812 C15.7501296,16.8254925 13.694246,16.8297891 13.3214552,15.4054743 C12.8387976,16.0598965 13.0126407,16.989568 13.567103,17.5387241 C14.1318231,18.0980845 14.956768,18.3357389 15.7320434,18.1748858 C16.9910562,17.9138689 17.929107,16.8117972 18.141282,15.5733093 C18.4074455,14.0155321 17.5077265,12.5678547 16.1086135,11.8992001 C14.735685,11.2431667 13.166778,11.4389294 11.8783415,12.1835797 C8.93569621,13.884218 8.09698426,17.8513 10.0745844,20.5978859 C11.9215324,23.162136 15.5816854,23.6409356 18.3564263,22.4008365 C21.7717413,20.8747465 23.6448736,16.9466023 22.7972536,13.3415068"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="tips__mode-title__inner">flashing red light</div>
        </div>
        <div class="tips__mode-text">
          <div class="tips__mode-text-item">Jar isn’t aligned with base.</div>
          <div class="tips__mode-text-item">
            Loosen the jar from the base and screw it back in until the arrows
            on the back of the jar and base line up. The arrows are meant to be
            a guide, but they might not line up perfectly, and that's okay. The
            best way to tell when the jar is properly aligned will be looking at
            the power button the front - when it flashes white instead of red,
            that means you're good to go!
          </div>
          <video
            autoplay=""
            loop=""
            muted=""
            height="350"
            webkit-playsinline=""
            playsinline=""
          >
            <source
              src="//videos.ctfassets.net/strhx3d94c40/6wgnawGCMSnkuvcowHMZ2H/558488cad8a61edbd13d1c0a7aab456c/MisalignedJars_1.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
      <div class="tips__mode">
        <div class="tips__mode-title title" @click="readMore($event)">
          <div class="tips__mode-title-icon">
            <svg width="29px" height="29px" viewBox="0 0 29 29">
              <title>Group 13</title>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-185.000000, -3618.000000)">
                  <g transform="translate(160.000000, 3137.000000)">
                    <g transform="translate(0.000000, 62.000000)">
                      <g transform="translate(0.000000, 406.000000)">
                        <g transform="translate(25.000000, 13.000000)">
                          <circle
                            stroke="#E7252C"
                            stroke-width="1.5"
                            cx="14.5"
                            cy="14.5"
                            r="13.75"
                          ></circle>
                          <path
                            d="M21.7972536,12.3415068 C21.1955513,9.7901465 19.3145906,7.44931841 16.7193616,6.73125321 C14.8902299,6.22533143 12.9417835,6.59886497 11.2462734,7.37359126 C10.7279834,7.61043999 10.221301,7.88300401 9.75160072,8.20497864 C12.1913428,7.22347965 15.1258898,7.60399513 17.1666566,9.27912254 C19.1107839,10.8744948 19.9451768,13.4919149 19.0565254,15.8765144 C18.5420146,17.2573264 17.5337787,18.4579507 16.1487027,19.0320806 C14.8243638,19.5815052 13.2751627,19.5149083 12.0615004,18.7275608 C11.0246505,18.0551467 10.3114619,16.8819131 10.240197,15.6391286 C10.1621836,14.2816792 10.9336798,12.9776685 12.2766447,12.5772814 C13.5200007,12.2067017 15.539442,12.7110123 15.1123927,14.3968812 C14.7501296,15.8254925 12.694246,15.8297891 12.3214552,14.4054743 C11.8387976,15.0598965 12.0126407,15.989568 12.567103,16.5387241 C13.1318231,17.0980845 13.956768,17.3357389 14.7320434,17.1748858 C15.9910562,16.9138689 16.929107,15.8117972 17.141282,14.5733093 C17.4074455,13.0155321 16.5077265,11.5678547 15.1086135,10.8992001 C13.735685,10.2431667 12.166778,10.4389294 10.8783415,11.1835797 C7.93569621,12.884218 7.09698426,16.8513 9.07458444,19.5978859 C10.9215324,22.162136 14.5816854,22.6409356 17.3564263,21.4008365 C20.7717413,19.8747465 22.6448736,15.9466023 21.7972536,12.3415068"
                            fill="#E7252C"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="tips__mode-title__inner">Solid Red Light</div>
        </div>
        <div class="tips__mode-text">
          <div class="tips__mode-text-item">Blades are blocked.</div>
          <div class="tips__mode-text-item">
            Flip your BlendJet upside down and give it a shake to loosen the
            jam. Start blending upside down, then turn it right-side up again so
            your ingredients hit the blades at full speed.
          </div>
          <video
            autoplay=""
            loop=""
            muted=""
            height="350"
            webkit-playsinline=""
            playsinline=""
          >
            <source
              src="//videos.ctfassets.net/strhx3d94c40/4gg4I9BgCJ4EPiklNgD5Yh/b6c2f1bceabe16a93b6a7491690bdb2c/RegularJam_Updated_1.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <div class="tips-title title">CLEANING</div>
      <div class="tips-desc">
        Always clean your BlendJet immediately after each use to prevent food
        from drying and sticking to the blades. Your BlendJet 2 is
        water-resistant, so it’s okay if the USB port gets wet, but never put
        your BlendJet—including the base, jar and lid—in the dishwasher, or
        submerge it entirely under water. Gently pat the BlendJet logo to dry,
        or simply let air dry.
      </div>
      <div class="tips__mode">
        <div class="tips__mode-title title" @click="readMore($event)">
          <div class="tips__mode-title-icon">
            <svg width="29px" height="29px" viewBox="0 0 29 29">
              <title>Group 16</title>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-185.000000, -2477.000000)">
                  <g transform="translate(160.000000, 2396.000000)">
                    <g transform="translate(0.000000, 62.000000)">
                      <g transform="translate(25.000000, 19.000000)">
                        <circle
                          stroke="#1E90BB"
                          stroke-width="1.5"
                          cx="14.5"
                          cy="14.5"
                          r="13.75"
                        ></circle>
                        <path
                          d="M21.7972536,12.3415068 C21.1955513,9.7901465 19.3145906,7.44931841 16.7193616,6.73125321 C14.8902299,6.22533143 12.9417835,6.59886497 11.2462734,7.37359126 C10.7279834,7.61043999 10.221301,7.88300401 9.75160072,8.20497864 C12.1913428,7.22347965 15.1258898,7.60399513 17.1666566,9.27912254 C19.1107839,10.8744948 19.9451768,13.4919149 19.0565254,15.8765144 C18.5420146,17.2573264 17.5337787,18.4579507 16.1487027,19.0320806 C14.8243638,19.5815052 13.2751627,19.5149083 12.0615004,18.7275608 C11.0246505,18.0551467 10.3114619,16.8819131 10.240197,15.6391286 C10.1621836,14.2816792 10.9336798,12.9776685 12.2766447,12.5772814 C13.5200007,12.2067017 15.539442,12.7110123 15.1123927,14.3968812 C14.7501296,15.8254925 12.694246,15.8297891 12.3214552,14.4054743 C11.8387976,15.0598965 12.0126407,15.989568 12.567103,16.5387241 C13.1318231,17.0980845 13.956768,17.3357389 14.7320434,17.1748858 C15.9910562,16.9138689 16.929107,15.8117972 17.141282,14.5733093 C17.4074455,13.0155321 16.5077265,11.5678547 15.1086135,10.8992001 C13.735685,10.2431667 12.166778,10.4389294 10.8783415,11.1835797 C7.93569621,12.884218 7.09698426,16.8513 9.07458444,19.5978859 C10.9215324,22.162136 14.5816854,22.6409356 17.3564263,21.4008365 C20.7717413,19.8747465 22.6448736,15.9466023 21.7972536,12.3415068"
                          fill="#437CBF"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="tips__mode-title__inner">basic cleaning</div>
        </div>
        <div class="tips__mode-text">
          <div class="tips__mode-text-item">
            Fill the jar about 2/3 full with warm (not hot) water, add a drop of
            dish soap and blend for one full cycle until your BlendJet
            automatically stops. Pour out liquid and rinse with clean water.
          </div>
          <video
            autoplay=""
            loop=""
            muted=""
            height="350"
            webkit-playsinline=""
            playsinline=""
          >
            <source
              src="//videos.ctfassets.net/strhx3d94c40/3LD9r7tdGPi6vkLAV6zoN9/7b52a63a24f6c3014567e76157cd65a1/RegularClean_Updated_1.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
      <div class="tips__mode">
        <div class="tips__mode-title title" @click="readMore($event)">
          <div class="tips__mode-title-icon">
            <svg width="29px" height="29px" viewBox="0 0 29 29">
              <title>Group 16</title>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-185.000000, -2477.000000)">
                  <g transform="translate(160.000000, 2396.000000)">
                    <g transform="translate(0.000000, 62.000000)">
                      <g transform="translate(25.000000, 19.000000)">
                        <circle
                          stroke="#1E90BB"
                          stroke-width="1.5"
                          cx="14.5"
                          cy="14.5"
                          r="13.75"
                        ></circle>
                        <path
                          d="M21.7972536,12.3415068 C21.1955513,9.7901465 19.3145906,7.44931841 16.7193616,6.73125321 C14.8902299,6.22533143 12.9417835,6.59886497 11.2462734,7.37359126 C10.7279834,7.61043999 10.221301,7.88300401 9.75160072,8.20497864 C12.1913428,7.22347965 15.1258898,7.60399513 17.1666566,9.27912254 C19.1107839,10.8744948 19.9451768,13.4919149 19.0565254,15.8765144 C18.5420146,17.2573264 17.5337787,18.4579507 16.1487027,19.0320806 C14.8243638,19.5815052 13.2751627,19.5149083 12.0615004,18.7275608 C11.0246505,18.0551467 10.3114619,16.8819131 10.240197,15.6391286 C10.1621836,14.2816792 10.9336798,12.9776685 12.2766447,12.5772814 C13.5200007,12.2067017 15.539442,12.7110123 15.1123927,14.3968812 C14.7501296,15.8254925 12.694246,15.8297891 12.3214552,14.4054743 C11.8387976,15.0598965 12.0126407,15.989568 12.567103,16.5387241 C13.1318231,17.0980845 13.956768,17.3357389 14.7320434,17.1748858 C15.9910562,16.9138689 16.929107,15.8117972 17.141282,14.5733093 C17.4074455,13.0155321 16.5077265,11.5678547 15.1086135,10.8992001 C13.735685,10.2431667 12.166778,10.4389294 10.8783415,11.1835797 C7.93569621,12.884218 7.09698426,16.8513 9.07458444,19.5978859 C10.9215324,22.162136 14.5816854,22.6409356 17.3564263,21.4008365 C20.7717413,19.8747465 22.6448736,15.9466023 21.7972536,12.3415068"
                          fill="#437CBF"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="tips__mode-title__inner">deep cleaning as needed</div>
        </div>
        <div class="tips__mode-text">
          <div class="tips__mode-text-item">
            <ul class="anatomy-guide__list">
              <li>
                Remove the jar from the base: twist the jar counterclockwise
                while twisting the base in the opposite direction.
              </li>
              <li>
                Using a fork, carefully remove the silicone gaskets from the lid
                and base for a more thorough clean. Take note of which gasket
                goes where, as the two are slightly different. The one with the
                groove goes in the base.
              </li>
              <li>Wash the jar, lid and gaskets in soapy water.</li>
              <li>
                Use a dish brush to dislodge any debris around the blades, if
                needed. Never put your hands in the path of the blades.
              </li>
              <li>
                Each part should be left out long enough to fully air dry.
              </li>
              <li>
                To reassemble your BlendJet, place the grooved gasket back in
                the base. Make sure the grooved side is facing down. Use the tip
                of a spoon, if needed, to press it firmly into place. This will
                help to prevent leaking. Next, place the jar on top and screw it
                into the base to push in the gasket fully.
              </li>
              <li>
                Place the second gasket back in the lid, pushing it it into
                place with either your fingers or a spoon.
              </li>
            </ul>
          </div>
          <video
            autoplay=""
            loop=""
            muted=""
            height="350"
            webkit-playsinline=""
            playsinline=""
          >
            <source
              src="//videos.ctfassets.net/strhx3d94c40/5u1TQAjtQQUcG8u3Rb185I/dadd17ded2cb08c70c031b7cddfbae52/DeepClean_Updated_1.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    </div>

    <div class="safeguard">
      <div class="safeguard-title title">
        IMPORTANT SAFEGUARDS & CAUTIONARY INFORMATION
      </div>
      <div class="safeguard-desc">
        Please review these precautions carefully before using your BlendJet for
        the first time.
      </div>
      <div class="safeguard__item">
        <div class="safeguard__item-title title">
          DO<span style="text-transform: lowercase">s</span>
        </div>
        <div class="safeguard__item__list">
          <div class="sageguard__item__list-item">
            DO store your BlendJet in a secure place, out of the reach of
            children.
          </div>
          <div class="sageguard__item__list-item">
            DO operate your BlendJet with the lid securely screwed on.
          </div>
          <div class="sageguard__item__list-item">
            DO use only high-quality USB chargers (like the one that came with
            your phone) and the included USB-C cable or certified USB-C cables.
          </div>
          <div class="sageguard__item__list-item">
            DO firmly push the USB cable all the way in to charge (do not plug
            it in partially).
          </div>
          <div class="sageguard__item__list-item">
            DO allow ingredients to settle in your BlendJet before you unscrew
            the lid after blending.
          </div>
          <div class="sageguard__item__list-item">
            DO take care when handling the base of the blender without the jar
            attached. As a safety precaution, there are magnets in the jar and
            base of the blender that must be aligned for BlendJet to work.
            Magnetic objects can fool the base into thinking that the jar is
            attached and allow the blades to spin.
          </div>
        </div>
      </div>

      <div class="safeguard__item safeguard__item--dont">
        <div class="safeguard__item-title title">
          DO NOT<span style="text-transform: lowercase">s</span>
        </div>
        <div class="safeguard__item__list">
          <div class="sageguard__item__list-item">
            DO NOT charge your BlendJet when it's wet. Use only high-quality USB
            chargers (like the one that came with your phone) and the included
            USB-C cable or certified USB-C cables.
          </div>
          <div class="sageguard__item__list-item">
            DO NOT let children use BlendJet unattended. Adult supervision is
            necessary at all times. Store in a safe place outside of the reach
            of children.
          </div>
          <div class="sageguard__item__list-item">
            DO NOT put your hands inside your BlendJet or anywhere near the
            blades. The blades can still spin when the lid is off.
          </div>
          <div class="sageguard__item__list-item">
            DO NOT put your BlendJet in the dishwasher. Your BlendJet is
            water-resistant, so it's okay if the USB port gets wet, but NEVER
            charge when wet, and NEVER submerge it underwater.
          </div>
          <div class="sageguard__item__list-item">
            DO NOT put your BlendJet in a microwave oven or freezer.
          </div>
          <div class="sageguard__item__list-item">
            DO NOT use hot liquids over 120°F / 49°C. This can cause damage to
            the jar and base, including leaks.
          </div>
          <div class="sageguard__item__list-item">
            DO NOT put anything carbonated in your BlendJet. This may cause
            pressure to build up in the jar & can result in a mess, possible
            injury, or damage to the blender (immediately or over time).
          </div>
          <div class="sageguard__item__list-item">
            DO NOT put MCT oil (or powder) in your BlendJet; over time, this can
            damage the blender.
          </div>
          <div class="sageguard__item__list-item">
            DO NOT let food sit in your BlendJet for prolonged periods. This may
            lead to fermentation and build pressure in the jar to the point
            where it bursts, resulting in a mess, damage or possible injury.
          </div>
          <div class="sageguard__item__list-item">
            DO NOT run your BlendJet while it’s empty as this can damage the
            blender.
          </div>
          <div class="sageguard__item__list-item">
            DO NOT immerse the charging cable in water or other liquids to avoid
            risk of electric shock.
          </div>
          <div class="sageguard__item__list-item">
            DO NOT allow the charging cable to touch hot surfaces.
          </div>
        </div>
      </div>
      <div class="contact-us">
        <div class="contact-us-title title">Contact Us</div>
        <a class="contact-us-link title" href="SUPPORT@BLENDJET.COM"
          >SUPPORT@BLENDJET.COM</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    readMore(event) {
      if (window.innerWidth > 900) {
        let button = event.target;
        const parentElement = button.parentElement;
        parentElement
          .querySelector(".tips__mode-text")
          .classList.toggle("tips__mode-text--active");
      }
    },
  },
};
</script>

<style scoped>
.container {
  padding: 0 10vw;
}
.anatomy {
  color: #999;
  font-weight: Regular;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 1.21;
  font-weight: 400;
  margin-top: 30px;
}
.title {
  color: #373975;
  font-weight: Medium;
  font-size: 28px;
  letter-spacing: 5px;
  line-height: 1.14;
  margin-bottom: 30px;
  text-transform: uppercase;
}
.anatomy-title {
  margin-bottom: 0px;
}
.anatomy-img {
  display: flex;
  justify-content: center;
}
.anatomy-guide__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.anatomy-guide__item-text {
  max-width: 600px;
  width: 100%;
}
.anatomy-guide-desc {
  margin-bottom: 15px;
}
.anatomy-guide__list li {
  position: relative;
  counter-increment: guide-counter;
  list-style-type: none;
  padding-left: 40px;
}

.anatomy-guide__list li + li {
  margin-top: 30px;
}

.anatomy-guide__list li::before {
  background: #373975;
  border-radius: 50%;
  color: #fff;
  content: counter(guide-counter);
  position: absolute;
  width: 32px;
  height: 32px;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 700;
  left: 0;
  line-height: 34px;
}
.safeguard-desc {
  margin-bottom: 60px;
}
.safeguard__item {
  margin-bottom: 60px;
}
.safeguard__item__list {
  display: flex;
  flex-wrap: wrap;
}
.sageguard__item__list-item {
  position: relative;
  max-width: 50%;
  width: 100%;
  color: #999;
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 1.21;
  margin-bottom: 35px;
  padding-left: 40px;
}
.sageguard__item__list-item::before {
  content: "";
  background-image: url("@/assets/icons/Todo.svg");
  width: 34px;
  height: 34px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.safeguard__item--dont .sageguard__item__list-item::before {
  background-image: url("@/assets/icons/DontDo.svg");
}
.contact-us {
  margin-bottom: 40px;
}
.contact-us-title {
  margin-bottom: 15px;
}
.contact-us-link {
  color: #373975;
  font-weight: Bold;
  font-size: 12px;
  letter-spacing: 1.75px;
  line-height: 2;
  text-transform: uppercase;
}
.tips-desc a {
  color: #3273dc;
}
.tips__mode-title {
  color: #373975;
  font-weight: Bold;
  font-size: 12px;
  letter-spacing: 1.75px;
  line-height: 1.5;
  margin-right: 35px;
  text-transform: uppercase;
  margin-top: 25px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}
.tips__mode-title__inner {
  pointer-events: none;
}
.tips__mode-title::before {
  content: "";
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("@/assets/icons/BlendMode-1.svg");
}
.tips__mode-text {
  display: none;
}
.tips__mode-text--active {
  display: block;
}
.tips__mode-text-item {
  margin-bottom: 15px;
}
.tips__mode-title-icon {
  display: flex;
  align-items: center;
  margin-right: 24px;
}
.anatomy-v-img {
  height: auto;
  max-width: 925px;
}
@media (max-width: 1150px) {
  .anatomy-guide__item {
    flex-wrap: wrap;
    justify-content: center;
  }
  .anatomy-guide-title {
    text-align: center;
    margin-bottom: 15px;
  }
  .anatomy-guide-desc {
    text-align: center;
  }
  .anatomy-guide__list {
    margin-bottom: 20px;
  }
  .anatomy-guide__item-text {
    max-width: none;
  }
}
@media (max-width: 900px) {
  .sageguard__item__list-item {
    max-width: none;
  }
  .tips-title {
    text-align: center;
  }
  .tips-desc {
    text-align: center;
  }
  .tips__mode-text {
    display: flex;
    flex-direction: column;
  }
  .safeguard__item-title {
    text-align: center;
  }
  .anatomy-v-img {
    max-width: none;
    width: 100%;
    height: auto;
  }
}
@media (max-width: 678px) {
  .sageguard__item__list-item {
    font-size: 12px;
  }
}
@media (max-width: 628px) {
  .safeguard-title {
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 16px;
  }
  .safeguard-desc {
    margin-bottom: 30px;
    text-align: center;
  }
  .anatomy-title {
    text-align: center;
  }
}
@media (max-width: 500px) {
  .container.anatomy {
    padding: 0 20px;
  }
  .title {
    font-size: 20px;
  }
  .anatomy-guide__item-text {
    font-size: 12px;
  }
  .contact-us-title {
    margin-bottom: 0;
  }
  .contact-us {
    text-align: center;
  }
  .tips__mode-text-item {
    font-size: 13px;
    line-height: 14px;
    text-align: justify;
  }
}
@media (max-width: 400px) {
  .contact-us-link {
    font-size: 16px;
  }
  .safeguard__item-title {
    margin-bottom: 10px;
  }
  .safeguard__item {
    margin-bottom: 30px;
  }
  .tips__mode-title__inner {
    font-size: 16px;
    line-height: 18px;
  }
}
</style>
