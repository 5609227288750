<template>
  <div class="follow">
    <div class="follow-title">
      <a href="https://www.instagram.com/blendjet/">@blendjet</a>
      <div class="follow-img">
        <v-img src="@/assets/icons/check.png" alt="check" />
      </div>
    </div>
    <div class="follow-amount">726K FOLLOWERS ON INSTAGRAM</div>
    <div class="follow-text">
      Get inspired with recipes, tips, and tricks from fellow BlendJetters.
      <span>#blendeverywhere</span>
    </div>
    <div class="follow-link">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="16"
        viewBox="0 0 15 16"
      >
        <path
          fill="#373795"
          d="M10.509 15.01c1.192-.057 2.248-.33 3.118-1.203.87-.87 1.142-1.925 1.201-3.117.07-1.229.07-4.908 0-6.136-.056-1.192-.328-2.248-1.201-3.118C12.757.566 11.7.294 10.509.234c-1.229-.07-4.911-.07-6.14 0C3.182.29 2.126.563 1.253 1.433.379 2.303.11 3.358.05 4.55c-.07 1.229-.07 4.911 0 6.14.056 1.192.329 2.248 1.202 3.117.873.87 1.926 1.143 3.118 1.202 1.228.07 4.91.07 6.139 0zm-2.851-1.288H7.44c-1.083 0-3.41.085-4.386-.3-.651-.259-1.152-.76-1.415-1.414-.388-.98-.299-3.304-.299-4.386 0-1.083-.086-3.41.3-4.386.258-.651.76-1.153 1.414-1.415.98-.388 3.303-.299 4.386-.299 1.082 0 3.41-.086 4.386.3.65.258 1.152.76 1.414 1.414.389.98.3 3.303.3 4.386 0 1.082.089 3.41-.3 4.386-.259.65-.76 1.152-1.414 1.414-.918.364-3.018.308-4.17.3zm3.754-9.182c.491 0 .89-.395.89-.89 0-.49-.399-.89-.89-.89-.492 0-.89.4-.89.89 0 .492.395.89.89.89zM7.44 11.437c2.112 0 3.815-1.704 3.815-3.815 0-2.112-1.703-3.815-3.815-3.815S3.626 5.51 3.626 7.622c0 2.111 1.703 3.815 3.815 3.815zm0-1.335c-1.365 0-2.48-1.112-2.48-2.48 0-1.368 1.112-2.48 2.48-2.48 1.368 0 2.48 1.112 2.48 2.48 0 1.368-1.116 2.48-2.48 2.48z"
        ></path>
      </svg>
      <div class="follow-link-text">
        <a href="https://www.instagram.com/blendjet/">FOLLOW US</a>
      </div>
    </div>
  </div>
  <div class="follow-swiper">
    <swiper
      :slides-per-view="4"
      loop
      @mouseenter="showNavigation = true"
      @mouseleave="showNavigation = false"
			:breakpoints="swiperBreakpoints"
			:autoplay="{
				delay: 3000
			}"
    >
      <swiper-slide
        ><a class="slider-link" href="#"
          ><v-img
            class="slider-img"
            src="@/assets/slider/product-1.jpg"
            alt /></a
      ></swiper-slide>
      <swiper-slide
        ><a class="slider-link" href="#"
          ><v-img
            class="slider-img"
            src="@/assets/slider/product-2.jpg"
            alt /></a
      ></swiper-slide>
      <swiper-slide
        ><a class="slider-link" href="#"
          ><v-img
            class="slider-img"
            src="@/assets/slider/product-3.jpg"
            alt /></a
      ></swiper-slide>
      <swiper-slide
        ><a class="slider-link" href="#"
          ><v-img
            class="slider-img"
            src="@/assets/slider/product-4.jpg"
            alt /></a
      ></swiper-slide>
      <swiper-slide
        ><a class="slider-link" href="#"
          ><v-img
            class="slider-img"
            src="@/assets/slider/product-5.jpg"
            alt /></a
      ></swiper-slide>
      <swiper-slide
        ><a class="slider-link" href="#"
          ><v-img
            class="slider-img"
            src="@/assets/slider/product-6.jpg"
            alt /></a
      ></swiper-slide>
      <swiper-slide
        ><a class="slider-link" href="#"
          ><v-img
            class="slider-img"
            src="@/assets/slider/product-7.jpg"
            alt /></a
      ></swiper-slide>
      <swiper-slide
        ><a class="slider-link" href="#"
          ><v-img
            class="slider-img"
            src="@/assets/slider/product-8.jpg"
            alt /></a
      ></swiper-slide>
    </swiper>
  </div>
</template>
<script>
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// Import Swiper styles
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
			showNavigation: false,
			swiperBreakpoints: {
				1300: {
					slidesPerView: 4,
				},
				1000: {
					slidesPerView:3,
				},
				700: {
					slidesPerView:2,
				},
				300: {
					slidesPerView:1,
				}
			}
    };
  },
	
};
</script>

<style>
.follow-slider .slider-img {
  height: 100%;
}
.follow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.follow-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #373975;
  font-weight: BoldItalic;
  font-size: 54px;
  letter-spacing: 1px;
  line-height: 1;
}

.follow-amount {
  font-weight: Bold;
  font-size: 12px;
  letter-spacing: 1.75px;
  line-height: 1.17;
  margin-top: 5px;
  text-transform: uppercase;
  color: #373975;
  margin-top: 5px;
}
.follow-text {
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-bottom: 35px;
  margin-top: 19px;
  color: #373975;
  font-weight: 400;
  text-align: center;
  margin-bottom: 35px;
  margin-top: 19px;
  line-height: 1.5;
}

.follow-text span {
  display: block;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 700;
  color: #373975;
}
.follow-link {
  display: flex;
  align-items: center;
  background: transparent;
  border: 2px solid #373975;
  border-radius: 40px;
  color: #373975;
  cursor: pointer;
  font-weight: Bold;
  font-size: 12px;
  letter-spacing: 1.75px;
  text-transform: uppercase;
  padding: 10px 100px;
}
.follow-link:hover {
  opacity: 0.9;
}
.follow-link-text {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

@media(max-width: 400px){
	.follow-link{
		padding: 10px 85px;
	}
}
</style>