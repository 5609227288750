<template>
  <div class="header__cart-container">
    <button class="header__cart-btn">0</button>
  </div>
  <div class="header__cart-wrapper">
    <div class="header__cart">
      <div class="haeder__cart__top">
        <div class="header__cart-title">Your Cart</div>
        <div class="header__cart-close">
          <svg
            width="14px"
            height="14px"
            viewBox="0 0 14 14"
            data-v-f7419e7a=""
          >
            <g
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
              data-v-f7419e7a=""
            >
              <g
                transform="translate(-894.000000, -318.000000)"
                fill="currentColor"
                stroke="#373975"
                data-v-f7419e7a=""
              >
                <g
                  transform="translate(895.000000, 319.000000)"
                  data-v-f7419e7a=""
                >
                  <path
                    d="M11.52,3.16191517e-13 L12,0.48 L6.479,6 L12,11.52 L11.52,12 L5.999,6.48 L0.48,12 L-5.45410939e-13,11.52 L5.52,6 L-3.28403971e-13,0.48 L0.48,7.46472328e-13 L5.999,5.52 L11.52,3.16191517e-13 Z"
                    data-v-f7419e7a=""
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <div class="header__cart__main">
        <div
          class="header__cart__main-item"
          v-for="item in cart"
          :key="item.id"
        >
          <div class="header__cart__main-item__img">
            <img :src="item.path_img" class="header__cart__main-item-img" />
          </div>
          <div class="header__cart__main-item__text">
            <div class="header__cart__main-item-title">{{ item.name }}</div>
            <div class="header__cart__main-item-amount">Amount: {{ item.amount }}</div>
          </div>
        </div>
      </div>
      <div class="header__cart__bottom">
        <div class="header__cart__bottom-shipping">
          <svg height="15px" viewBox="0 0 42 15">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g>
                <path
                  d="M31.7462908,6.80922432 L30.5059347,2.90566038 C30.3931751,2.55345912 30.0548961,2.28930818 29.6884273,2.28930818 L25.8545994,2.28930818 L25.8545994,0.410901468 C25.8545994,0.176100629 25.6854599,0 25.4881306,0 L13.3664688,0 C13.1691395,0 13,0.176100629 13,0.410901468 L13,11.0943396 C13,11.2997904 13.1691395,11.475891 13.3664688,11.475891 L15.227003,11.475891 C15.227003,11.475891 15.227003,11.475891 15.227003,11.475891 C15.227003,12.884696 16.3264095,14 17.6513353,14 C18.9762611,14 20.0756677,12.8553459 20.0756677,11.475891 C20.0756677,11.475891 20.0756677,11.475891 20.0756677,11.475891 L24.8961424,11.475891 C24.8961424,11.475891 24.8961424,11.475891 24.8961424,11.475891 C24.8961424,12.884696 25.995549,14 27.3204748,14 C28.6454006,14 29.7448071,12.8553459 29.7448071,11.475891 C29.7448071,11.475891 29.7448071,11.475891 29.7448071,11.475891 L31.5771513,11.475891 C31.8026706,11.475891 32,11.2704403 32,11.0356394 L32,8.5115304 C32,7.9245283 31.9154303,7.36687631 31.7462908,6.80922432 Z M17.6513353,12.6792453 C17.0311573,12.6792453 16.495549,12.1509434 16.495549,11.475891 C16.495549,10.8301887 17.0029674,10.2725367 17.6513353,10.2725367 C18.2715134,10.2725367 18.8071217,10.8008386 18.8071217,11.475891 C18.8071217,12.1509434 18.2997033,12.6792453 17.6513353,12.6792453 Z M27.3486647,12.6792453 C26.7284866,12.6792453 26.1928783,12.1509434 26.1928783,11.475891 C26.1928783,10.8301887 26.7002967,10.2725367 27.3486647,10.2725367 C27.9970326,10.2725367 28.4762611,10.8301887 28.4762611,11.475891 C28.4762611,12.1509434 27.9688427,12.6792453 27.3486647,12.6792453 Z M29.9985163,6.31027254 L26.2210682,6.31027254 C26.0237389,6.31027254 25.8827893,6.16352201 25.8827893,5.95807128 L25.8827893,3.99161426 C25.8827893,3.78616352 26.0237389,3.639413 26.2210682,3.639413 L29.1246291,3.639413 C29.2655786,3.639413 29.4065282,3.72746331 29.462908,3.87421384 L30.1676558,6.0754717 C30.1958457,6.19287212 30.111276,6.31027254 29.9985163,6.31027254 Z"
                  fill="currentColor"
                  fill-rule="nonzero"
                ></path>
                <line
                  x1="10"
                  y1="9.5"
                  x2="-1.06858966e-14"
                  y2="9.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                ></line>
                <line
                  x1="10"
                  y1="3.5"
                  x2="5.5"
                  y2="3.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                ></line>
                <line
                  x1="10"
                  y1="6.5"
                  x2="3.5"
                  y2="6.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                ></line>
              </g>
            </g>
          </svg>
          FREE WORLDWIDE SHIPPING
        </div>
        <div class="header__cart__bottom-checkout">
          <button @click="submit()">checkout</button>
        </div>
        <div class="header__cart__bottom-certificates">
          <a href="#"><v-img src="@/assets/trusted-site.svg" /></a>
          <a href="#"><v-img src="@/assets/norton-site-seal.svg" /></a>
          <a href="#"><v-img src="@/assets/bbb-seal.svg" /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { submitCart} from '@/requestService.js';
export default {
  mounted() {
    const headerCart = document.querySelector(".header__cart-wrapper");
    document
      .querySelector(".header__cart-btn")
      .addEventListener("click", () => {
        headerCart.classList.toggle("header__cart--active");
      });
    document
      .querySelector(".header__cart-close")
      .addEventListener("click", () => {
        headerCart.classList.toggle("header__cart--active");
      });
  },
  methods: {
		submit() {
      submitCart();
    },
  },
};
</script>

<style scoped>
.header__cart-btn {
  background-color: rgb(141, 205, 3);
  border-radius: 50%;
  width: 27px;
  height: 27px;
  cursor: pointer;
  margin-right: 20px;
  font-size: 12px;
  color: #fff;
}
.header__cart-container {
  display: flex;
  align-items: center;
}
.header__cart-wrapper {
  display: none;
}
.header__cart {
  position: absolute;
  top: 0px;
  right: 0;
  background: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 400px;
	width: 100%;
  border-left: 2px solid;
  border-image: linear-gradient(to bottom, #89cff0, #0077b3) 1;
}
.haeder__cart__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 20px 0 21px;
}
.header__cart-title {
  color: #373975;
  font-weight: Bold;
  font-size: 12px;
  letter-spacing: 1.75px;
  line-height: 1.17;
}
.header__cart-close {
  cursor: pointer;
}
.header__cart__bottom-shipping {
  align-items: center;
  background-color: #fff;
  border: 1px solid #373975;
  border-radius: 7px;
  color: #373975;
  display: flex;
  font-size: 10px;
  height: 26px;
  justify-content: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin: 0 20px 7px;
}
.header__cart__bottom-checkout {
  padding: 0 20px;
  margin: 15px 0;
}
.header__cart__bottom-checkout button {
  background-color: #373975;
  border: none;
  border-radius: 40px;
  color: #fff;
  cursor: pointer;
  font-weight: Bold;
  font-size: 12px;
  height: 50px;
  letter-spacing: 1.75px;
  line-height: 14px;
  text-transform: uppercase;
  width: 100%;
  transition: opacity 0.5s;
}
.header__cart__bottom-checkout button:hover {
  opacity: 0.9;
}
.header__cart__bottom-certificates {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 20px;
  max-width: 356px;
  width: 100%;
}
.header__cart--active {
  display: block;
}
.header__cart__main {
	height: 100%;
	padding: 20px 15px;
	overflow: auto;
}
.header__cart__main-item {
  display: flex;
  align-items: center;
	margin-bottom: 20px;
}
.header__cart__main-item-img {
  width: 60px;
  height: 60px;
}
.header__cart__main-item-title {
	font-size: 16px;
	margin-bottom: 10px;
}
.header__cart__main-item__text{
	padding-left: 15px;
}
.header__cart__main-item-amount {
	text-transform: none;
}
</style>
